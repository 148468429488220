import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { apiGet, useApiMutation } from "../../../utils/api";
import { EllipsisMenu } from "../../../utils/ellipsis_menu";
import { PlatformIcon, SizeBadge } from "../../campaigns/utils";
import DataTable from "../data_table";
import { dealStatusBadge, dealStatusesForAdmin } from "../../../utils/deal_status";
import { formatCurrency, formatNummber } from "../../../utils/helpers";
import { useBulkInviteDrawer } from "./invite_bulk_drawer";
import { useSingleInviteDrawer } from "./invite_single_drawer";
import { useEditDealDrawer } from "./edit_deal_drawer";
import { useFetchCreatorsDrawer } from "./fetch_creators_drawer";

const emptyFilters = {
  sort: "handle",
  sortDir: "asc",
  page: 1,
};

export const DealsTab = ({ campaign }: { campaign: any }) => {
  const [filters, setFilters] = useState(emptyFilters);
  const { data, isSuccess, isFetching, refetch } = useQuery({
    queryKey: ["data", filters],
    queryFn: () =>
      apiGet(`${campaign._link}/deals`, { params: filters }).then((res) => res.data),
    keepPreviousData: true,
  });
  const BulkInviteDrawer = useBulkInviteDrawer();
  const SingleInviteDrawer = useSingleInviteDrawer();
  const EditDealDrawer = useEditDealDrawer();
  const FetchCreatorsDrawer = useFetchCreatorsDrawer();

  const { post } = useApiMutation();
  const deleteAllDeals = () => {
    if (confirm("Are you sure you want to delete all deals?")) {
      post(`/admin/campaigns/${campaign.uuid}/delete_all_deals`).then(() => {
        document.location.reload();
      });
    }
  };

  const { results, meta } = data || {};

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="fs-24 mr-2">Campaign Creators</h4>
        <EllipsisMenu
          actions={[
            { label: "Send invite email", action: () => BulkInviteDrawer.show() },
            {
              label: "Fetch More Creators",
              action: () => FetchCreatorsDrawer.show(),
            },
            { separator: true },
            {
              label: "Delete All Deals",
              action: () => deleteAllDeals(),
              className: "text-danger",
            },
          ]}
          horizontal
          name="invite-menu"
        />
      </div>

      {isSuccess && (
        <DataTable
          schema={[
            {
              name: "name",
              header: "Creator",
              value: (p) => (
                <Link to={`/admin/creators/${p.creator_uuid}`}>
                  {p.name || <span className="red">&lt;no name&gt;</span>}
                </Link>
              ),
            },
            {
              name: "handle",
              value: (p) => (
                <>
                  <PlatformIcon platform={p.platform} />
                  <a href={p.link} target="_blank" className="ml-2">
                    {p.handle}
                    <i className="bi bi-box-arrow-up-right gray ml-1 fs-10 vertical-align-middle" />
                  </a>
                </>
              ),
            },
            {
              name: "email",
              header: "Has email",
              value: (p) => (p.email ? "Yes" : "--"),
            },
            { name: "followers", type: "number" },
            { name: "size", value: (p) => <SizeBadge size={p.size} /> },
            { name: "categories", value: "categories" },
            { name: "country", value: "country" },
            { name: "gender", value: "gender" },
            {
              name: "impressions",
              header: "Exp. Plays",
              type: "number",
              value: (p) => formatNummber(p.impressions),
            },
            {
              name: "interactions",
              header: "Exp. Interactions",
              type: "number",
              value: (p) => formatNummber(p.interactions),
            },
            { name: "offer", value: (p) => formatCurrency(p.offer) },
            { name: "status", value: (p) => dealStatusBadge(p.status) },
            {
              name: "edited",
              value: (p) => new Date(p.updated_at).toLocaleDateString(),
            },
            {
              name: "",
              value: (p) => (
                <EllipsisMenu
                  actions={[
                    {
                      label: (
                        <div className="flex align-items-center">
                          View Profile
                          <i className="bi bi-box-arrow-up-right gray ml-1 fs-10" />
                        </div>
                      ),
                      target: "_blank",
                      link: `/admin/creators/${p.creator_uuid}`,
                    },
                    {
                      label: "Campaign Invitation",
                      action: () => SingleInviteDrawer.set(p),
                    },
                    {
                      label: "Edit Deal",
                      action: () => EditDealDrawer.set(p),
                      if: !campaign.packages_submitted_at,
                    },
                  ]}
                />
              ),
              sortable: false,
            },
          ]}
          data={results}
          filterControls={[
            { name: "search", type: "search" },
            {
              name: "deal_status",
              placeholder: "Status",
              options: dealStatusesForAdmin,
            },
          ]}
          filters={filters}
          setFilters={setFilters}
          reset={() => setFilters(emptyFilters)}
          sortable
          meta={meta}
          widths={[200, null, 300, 125, null, null, null, null, null, null, 50]}
          isFetching={isFetching}
        />
      )}

      <BulkInviteDrawer refetch={refetch} />
      <SingleInviteDrawer refetch={refetch} />
      <EditDealDrawer refetch={refetch} />
      <FetchCreatorsDrawer refetch={refetch} campaign={campaign} />
    </>
  );
};
