import React from "react";
import { PlatformIcon } from "./utils";
import { Drawer } from "../../utils/offcanvas";
import CreatorPlatformProfile from "./creator_platform_profile";
import { Tab, Tabs } from "react-bootstrap";

const CreatorDrawer = ({
  viewCreatorPlatform,
  onHide,
}: {
  viewCreatorPlatform: any;
  onHide: () => void;
}) => {
  return (
    <Drawer title="" show={!!viewCreatorPlatform} onHide={onHide}>
      {viewCreatorPlatform && (
        <>
          <div className="text-center">
            <h3>{viewCreatorPlatform.creator.name}</h3>
            <h6 className="fw-normal">
              {viewCreatorPlatform.creator.age_gender_display}
            </h6>
          </div>

          <Tabs className="mt-4">
            <Tab
              key={viewCreatorPlatform.platform}
              eventKey={viewCreatorPlatform.platform}
              title={
                <PlatformIcon
                  platform={viewCreatorPlatform.platform}
                  className="fs-18"
                  handle={viewCreatorPlatform.handle}
                  contrast
                />
              }
            >
              <CreatorPlatformProfile platform={viewCreatorPlatform} />
            </Tab>
          </Tabs>

          <div className="list-group-item border-0 mt-2 pl-1r">
            {viewCreatorPlatform.creator.creator_platforms.length > 1 && (
              <div>
                <h6 className="mb-1">Other Platforms</h6>
                <ul className="list-unstyled">
                  {viewCreatorPlatform.creator.creator_platforms
                    .filter((cp) => cp.platform !== viewCreatorPlatform.platform)
                    .map((elem, index) => (
                      <li key={index}>
                        <i className={`bi bi-${elem.platform}`}></i> {elem.handle}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        </>
      )}
    </Drawer>
  );
};

export default CreatorDrawer;
