// Entry point for the build script in your package.json
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import axios from "axios";
import AppRoutes from "../components/routes";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { last, startCase } from "lodash";
import { Dropdown } from "react-bootstrap";
import { Helmet } from "react-helmet";

const csrfToken = (document.querySelector("meta[name=csrf-token]") as HTMLMetaElement)
  ?.content;
axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
axios.defaults.headers.common["Accept"] = "application/json";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

const TopBarDropdown = () => {
  const userAttrs = (window as any).userAttrs;

  const switchers = [];

  if (userAttrs.is_agency && !location.pathname.startsWith("/campaigns")) {
    switchers.push(["Agency", "/campaigns"]);
  }
  if (userAttrs.is_creator && !location.pathname.startsWith("/creators")) {
    switchers.push(["Creator", "/creators"]);
  }
  if (userAttrs.is_admin && !location.pathname.startsWith("/admin")) {
    switchers.push(["Admin", "/admin"]);
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="" className="btn-text" name="topbar-user-menu">
          <span className="pr-1">{(window as any).userAttrs.name}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {switchers.map(([name, path]) => (
            <Dropdown.Item href={path} key={path}>
              Switch to {name}
            </Dropdown.Item>
          ))}
          {switchers.length > 0 && <Dropdown.Divider />}
          <Dropdown.Item
            href={null}
            onClick={(e) => e.currentTarget.querySelector("form").submit()}
            type="button"
          >
            <form className="nav-link flex" method="post" action="/users/sign_out">
              <input type="hidden" name="_method" value="delete" />
              <input className="btn btn-text red" type="submit" value="Logout" />
              <input type="hidden" name="authenticity_token" value={csrfToken} />
            </form>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

document.addEventListener("DOMContentLoaded", () => {
  const rootEl = document.getElementById("root");
  const topBarDropdownEl = document.getElementById("top-bar-dropdown");

  const component = (
    <>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </>
  );

  createRoot(rootEl).render(component);
  if (topBarDropdownEl) {
    createRoot(topBarDropdownEl).render(<TopBarDropdown />);
  }
});

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [pathname]);

  return null;
};

const inferTitle = (path, title) => {
  if (path.startsWith("/admin")) {
    return `Xrossworld - Admin - ${startCase(path.split("/")[2])}`;
  } else if (path.startsWith("/creators")) {
    return `Xrossworld - Admin - ${startCase(path.split("/")[2])}`;
  } else if (title) {
    return `Xrossworld - ${title}`;
  } else {
    return "Xrossworld";
  }
};

const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          {AppRoutes.map(([path, Component, title]) => (
            <Route
              key={path as string}
              path={path as string}
              Component={(props) => (
                <>
                  <Helmet>
                    <title>{inferTitle(path, title)}</title>
                  </Helmet>
                  <Component {...props} />
                </>
              )}
            />
          ))}
          <Route
            path="*"
            Component={() => {
              return <div>Not found</div>;
            }}
          />
        </Routes>
      </Router>

      <ToastContainer
        position="bottom-left"
        hideProgressBar
        autoClose={1750}
        transition={Slide}
      />
    </>
  );
};

window.addEventListener("keyup", (e) => {
  if (e.key === "e" && e.ctrlKey && (window as any).devToolStack.length) {
    const item = last((window as any).devToolStack as { fn: () => void; args: any[] }[]);
    const { fn, args } = item;
    fn?.apply(this, args);
  }
});
