import { Link } from "react-router-dom";
import { useApiMutation, usePageData } from "../../utils/api";
import { genericError } from "../../utils/errors";
import { clickPrimaryButton, useDevTool } from "../../utils/dev_tools";
import { EllipsisMenu } from "../../utils/ellipsis_menu";
import { formatDate, PlatformIcon } from "./utils";
import { capitalize } from "lodash";

const statusConfig = {
  onboarding_in_progress: {
    badge: "Onboarding in progress",
    link: (c) => `${c._link}/${c.next_step}`,
    cta: "Continue onboarding",
    ctaLink: (c) => `${c._link}/${c.next_step}`,
    level: "info",
  },
  onboarding_complete: {
    badge: "Curating creators",
    link: (c) => `${c._link}/details`,
    cta: null,
    menuViewCampaignDetails: true,
    level: "info",
  },
  packages_submitted: {
    badge: "Creator packages ready",
    link: (c) => c._link,
    cta: "Review packages",
    ctaLink: (c) => c._link,
    menuViewCampaignDetails: true,
    level: "warning",
  },
  package_selected: {
    badge: "Waiting for payment",
    cta: "View Invoice",
    link: (c) => `${c.selected_package._link}/checkout`,
    menuViewCampaignDetails: true,
    menuViewPackage: true,
    level: "warning",
  },
  campaign_in_progress: {
    badge: "Campaign in progress",
    cta: null,
    menuViewCampaignDetails: true,
    menuViewPackage: true,
    level: "info",
  },
  campaign_complete: {
    badge: "Campaign Complete",
    cta: null,
    menuViewCampaignDetails: true,
    menuViewPackage: true,
    level: "success",
  },
};

const CampaignsIndex = () => {
  const { data, refetch, unavailable } = usePageData();
  const { apiDelete } = useApiMutation();

  useDevTool(() => clickPrimaryButton(".btn-xw-outline-secondary"));

  if (unavailable) {
    return unavailable;
  }

  const { campaigns } = data;

  const deleteCampaign = async (campaign) => {
    const result = await apiDelete(campaign._link);
    if (result.data.success) {
      refetch();
    } else {
      genericError();
    }
  };

  return (
    <div id="main" className="container">
      <div className="heading d-flex align-items-center mb-3 border-bottom">
        <h1 className="me-auto">Your Campaign Dashboard</h1>
        <div className="actions">
          <Link className="btn btn-sm btn-xw-outline-secondary" to="/campaigns/intro">
            <i className="bi bi-plus" /> Create new campaign
          </Link>
        </div>
      </div>

      {campaigns.length ? (
        <div className="row">
          <div className="col">
            <table className="table table-tiles align-middle">
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th className="w-250px">Platform</th>
                  <th className="w-250px">Status</th>
                  <th className="">Created</th>
                  <th className="w-250px" />
                  <th className="text-end">Actions</th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {campaigns.map((campaign) => {
                  const status = statusConfig[campaign.status];

                  return (
                    <tr key={campaign.uuid}>
                      <td>
                        <h5 className="mb-0">
                          <Link to={status?.link?.(campaign)}>
                            {campaign.name || <div className="gray">(continue)</div>}
                          </Link>
                        </h5>
                        <p className="text-muted mb-0 small">{campaign.brand?.name}</p>
                      </td>
                      <td>
                        <PlatformIcon
                          platform={campaign.platform}
                          handle={capitalize(campaign.platform)}
                          contrast
                        />
                      </td>
                      <td>
                        <span className={`badge text-uppercase text-bg-${status.level}`}>
                          {status.badge}
                        </span>

                        <div className="form-text d-none">
                          Xrossworld is sourcing creators
                        </div>
                      </td>
                      <td>{formatDate(campaign.created_at)}</td>
                      <td className="text-end">
                        {status.cta && (
                          <Link
                            to={status?.link?.(campaign)}
                            className="btn btn-xw-primary"
                          >
                            {status.cta}
                          </Link>
                        )}
                      </td>
                      <td className="text-end w-50px">
                        <div className="dropdown">
                          <EllipsisMenu
                            actions={[
                              {
                                label: "View package",
                                link: campaign.selected_package?._link,
                                if: status.menuViewPackage,
                              },
                              {
                                label: "View campaign details",
                                link: `${campaign._link}/details`,
                                if: status.menuViewCampaignDetails,
                              },
                              {
                                label: "Delete Campaign",
                                action: () => deleteCampaign(campaign),
                                className: "text-danger",
                                if: campaign["deleteable?"],
                              },
                            ]}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col">
            <div className="text-center mt-5 pt-5">
              <h2 className="fs-32 white">No Active Campaigns</h2>
              <p className="my-4 light-gray fs-20">
                Create a new campaign to get started.
              </p>
              <Link to="/campaigns/intro" className="btn btn-lg btn-xw-primary">
                <i className="bi bi-plus"></i> Create New Campaign
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignsIndex;
