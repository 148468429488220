import { Field, Form, Formik, useFormik } from "formik";
import { useState } from "react";
import { useApiGet, useApiMutation, usePageData } from "../../../utils/api";
import { Drawer } from "../../../utils/offcanvas";
import { TextField } from "../../campaigns/fields";
import { toast } from "react-toastify";
import { SubmitButton } from "../../../utils/submit_button";

const AgencyForm = ({
  agencyId,
  onSuccess,
  close,
}: {
  agencyId: string;
  onSuccess: () => void;
  close: () => void;
}) => {
  const { post, put, saving } = useApiMutation();

  let data: { agency: any } = { agency: {} },
    unavailable,
    refetch;

  if (agencyId) {
    const fetch = useApiGet(`/admin/agencies/${agencyId}`);
    data = fetch.data;
    unavailable = fetch.unavailable;
    refetch = fetch.refetch;
  }

  const formik = useFormik({
    initialValues: data?.agency,
    enableReinitialize: true,
    onSubmit: (values) => {
      let method, path;
      if (agency.uuid) {
        [method, path] = [put, `/admin/agencies/${agency.uuid}`];
      } else {
        [method, path] = [post, `/admin/agencies`];
      }
      method(path, {
        agency: { ...values, users_attributes: values.users },
      }).then((res) => {
        if (res.data.success) {
          onSuccess?.();
          close();
          toast("Saved");
        } else {
          toast.error("Something went wrong");
          console.log(res?.data);
        }
      });
    },
  });

  const addUser = (values, formik) => {
    post(`/admin/agencies/${agencyId}/agency_users`, { user: values }).then((res) => {
      if (res.data.success) {
        onSuccess?.();
        refetch();
        formik.setValues({ name: "", email: "" });
        toast("Saved");
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  if (unavailable) {
    return unavailable;
  }

  const { agency } = data;

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <TextField label="Name" name="name" formik={formik} />

        <div className="mt-4">
          <button type="button" className="btn btn-secondary" onClick={() => close()}>
            Cancel
          </button>
          <SubmitButton className="btn btn-primary ml-2" loading={saving}>
            Save
          </SubmitButton>
        </div>
      </form>

      {agency.uuid && (
        <>
          <hr />
          <h2>Users</h2>

          {agency.users.length ? (
            <table className="table table-sm table-borderless mt-2">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {agency?.users?.map((user, i) => (
                  <tr key={user.uuid}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td className="w-200px text-right">
                      <ResetPassword user={user} agencyId={agencyId} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            "No users"
          )}

          <hr />

          <Formik initialValues={{ name: "", email: "" }} onSubmit={addUser}>
            <Form>
              <h2 className="">Add User</h2>
              <div className="row mt-2">
                <div className="col">
                  <label>Name</label>
                  <Field type="name" name="name" className="form-control" />
                </div>
                <div className="col">
                  <label>Email</label>
                  <Field type="email" name="email" className="form-control" />
                </div>
              </div>
              <SubmitButton className="btn btn-primary mt-3" loading={saving}>
                Add
              </SubmitButton>
            </Form>
          </Formik>
        </>
      )}
    </div>
  );
};

const ResetPassword = ({ user, agencyId }) => {
  const [sent, setSent] = useState(false);
  const { post, saving } = useApiMutation();

  if (!user.uuid) {
    return null;
  }

  const sendWelcomeEmail = () => {
    if (confirm(`Send email to ${user.email}?`)) {
      post(`/admin/agencies/${agencyId}/agency_users/${user.uuid}/reset_password`).then(
        (res) => {
          if (res.data.success) {
            toast("Email sent");
            navigator.clipboard.writeText(res.data.link);
            setSent(true);
          }
        }
      );
    }
  };

  return (
    <>
      {sent ? (
        "Email sent"
      ) : (
        <SubmitButton
          className="btn btn-text link-like"
          onClick={sendWelcomeEmail}
          loading={saving}
        >
          Send Welcome Email
        </SubmitButton>
      )}
    </>
  );
};

const AdminAgenciesIndex = () => {
  const { data, unavailable, refetch } = usePageData();
  const [formObject, setFormObject] = useState<{ uuid?: string } | undefined>();

  if (unavailable) {
    return unavailable;
  }

  const { agencies } = data;

  return (
    <div id="main">
      <div className="flex space-between align-items-center">
        <h1>Manage Agencies</h1>
        <button
          className="btn btn-primary btn-sm"
          onClick={() => {
            setFormObject({});
          }}
        >
          Add Agency
        </button>
      </div>

      <table className="table table-striped mt-4">
        <thead>
          <tr>
            <th>Name</th>
            <th>Members</th>
            <th className="w-50px"></th>
          </tr>
        </thead>
        <tbody>
          {agencies.map((a) => (
            <tr key={a.uuid}>
              <td>{a.name}</td>
              <td>
                {a.users.map((u) => (
                  <div key={u.uuid}>
                    • {u.name} <span className="text-muted small">({u.email})</span>
                  </div>
                ))}
              </td>
              <td>
                <button
                  className="btn btn-text link-color"
                  onClick={() => {
                    setFormObject(a);
                  }}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Drawer
        show={!!formObject}
        onHide={() => setFormObject(undefined)}
        title={`${formObject?.uuid ? "Edit" : "Add"} Agency`}
      >
        <AgencyForm
          agencyId={formObject?.uuid}
          onSuccess={refetch}
          close={() => setFormObject(undefined)}
        />
      </Drawer>
    </div>
  );
};

export default AdminAgenciesIndex;
