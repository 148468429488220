import { DrawerBase, makeModal } from "../../../utils/make_modal";
import { FetchCreatorsForm } from "./fetch_creators";

export const useFetchCreatorsDrawer = () =>
  makeModal(
    ({
      refetch,
      close,
      campaign,
    }: {
      refetch: () => void;
      close: () => void;
      campaign: any;
    }) => {
      return (
        <DrawerBase title="Fetch Creators" close={close}>
          <FetchCreatorsForm
            campaign={campaign}
            refetch={() => {
              refetch();
              close();
            }}
          />
        </DrawerBase>
      );
    }
  );
