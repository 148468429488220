import { useState } from "react";
import Papa from "papaparse";
import classNames from "classnames";
import { apiGet, useApiMutation } from "../../../utils/api";

let progressInterval;

const expected = {
  "1": "Tiktok Handle",
  "2": "Tiktok Link",
  "3": "Tiktok Followers",
  "4": "Instagram Handle",
  "6": "Instagram Link",
  "7": "Instagram Followers",
  "8": "Youtube Handle",
  "9": "Youtube Link",
  "10": "Youtube Followers",
  "11": "X Handle",
  "12": "X Link",
  "13": "X Followers",
  "14": "Linkedin Handle",
  "15": "Linkedin Link",
  "16": "Linkedin Followers",
  "0": "Category",
  "20": "First Name",
  "21": "Last Name",
  "22": "Job Title",
  "23": "Company",
  "24": "Ethnicity",
  "25": "Gender",
  "26": "Email",
  "27": "Phone",
  "28": "Location",
  "29": "Region",
  "30": "Country",
  "31": "State",
  "32": "City",
  "33": "Zip Code",
};

const empty = <span className="light-gray">--</span>;

const ImportCreators = () => {
  const [file, setFile] = useState<any[] | undefined>();
  const [rowCount, setRowCount] = useState(0);
  const [over, setOver] = useState(false);
  const [importing, setImporting] = useState(false);
  const [done, setDone] = useState(false);
  const [progress, setProgress] = useState(0);
  const { post } = useApiMutation();

  const processFile = (file) => {
    const hasValue = (v) => !["", null, "N/A", "--"].includes(v?.trim());

    Papa.parse(file, {
      complete: (results) => {
        const rows = [
          results.data[0],
          results.data.find((row, i) => i > 1 && hasValue(row[1])),
          results.data.find((row, i) => i > 1 && hasValue(row[4])),
          results.data.find((row, i) => i > 1 && hasValue(row[8])),
          results.data.find((row, i) => i > 1 && hasValue(row[11])),
          results.data.find((row, i) => i > 1 && hasValue(row[14])),
        ];
        setFile(rows);
        setRowCount(results.data.length);
      },
    });
  };

  const doUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const input = document.querySelector("input[type=file]") as HTMLInputElement;
    formData.append("file", input.files[0]);
    window.scrollTo({ top: 0, behavior: "instant" });
    setImporting(true);

    progressInterval = setInterval(() => {
      apiGet("/admin/creator_imports/progress").then((res) => {
        setProgress(res.data.count);
      });
    }, 1000);

    post("/admin/creator_imports", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => setDone(true))
      .finally(() => {
        setImporting(false);
        clearInterval(progressInterval);
      });
  };

  return (
    <div id="main" className="pb-10">
      <h1>Import Creators</h1>

      <form>
        <div className={classNames("mt-5", { "visibility-hidden": file })}>
          <label
            id="drop_zone"
            className={classNames("drop-zone", { over })}
            onDrop={(e) => {
              e.preventDefault();
              processFile(e.dataTransfer.files[0]);
              (
                (e.target as HTMLElement).querySelector(
                  "input[type=file]"
                ) as HTMLInputElement
              ).files = e.dataTransfer.files;
            }}
            onDragOver={(e) => {
              e.preventDefault();
              setOver(true);
            }}
            onDragEnter={(e) => {
              e.preventDefault();
              setOver(true);
            }}
            onDragLeave={() => setOver(false)}
            onDragEnd={() => setOver(false)}
          >
            Import File
            <input
              style={{ visibility: "hidden", position: "absolute" }}
              type="file"
              onChange={(e) => {
                processFile(e.target.files[0]);
              }}
            />
          </label>
        </div>

        {file && !importing && !done && (
          <div className="mt-5">
            <button className="btn btn-primary mb-3" onClick={doUpload}>
              Looks good. Import it.
            </button>
            <table className="table table-striped table-sm fs-12 import-table">
              <thead>
                <tr>
                  <th>Expected</th>
                  <th>
                    Row 1 <br />
                    Headers
                  </th>
                  <th>
                    Row 2 <br />
                    TikTok
                  </th>
                  <th>
                    Row 3 <br />
                    Instagram
                  </th>
                  <th>
                    Row 4 <br />
                    Youtube
                  </th>
                  <th>
                    Row 5 <br />X
                  </th>
                  <th>
                    Row 6 <br />
                    LinkedIn
                  </th>
                </tr>
              </thead>
              <tbody>
                {file[0].map((_, i) => (
                  <tr key={i}>
                    <td className="bold">{expected[i] || empty}</td>
                    <td>{file[0]?.[i] || empty}</td>
                    <td>{file[1]?.[i] || empty}</td>
                    <td>{file[2]?.[i] || empty}</td>
                    <td>{file[3]?.[i] || empty}</td>
                    <td>{file[4]?.[i] || empty}</td>
                    <td>{file[5]?.[i] || empty}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {importing && (
          <>
            <div className="meter mt-10">
              <span style={{ width: `${(progress / (rowCount || 1) || 1) * 100}%` }} />
            </div>
            <h3 className="text-center mt-2">
              Importing {progress.toLocaleString()} / {rowCount.toLocaleString()} Creators
            </h3>
          </>
        )}

        {done && <h3 className="mt-10">Import Done</h3>}
      </form>
    </div>
  );
};

export default ImportCreators;
