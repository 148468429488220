import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useApiGet } from "../../utils/api";
import { PlatformIcon, useTemplate2 } from "./utils";
import { clickPrimaryButton, useDevTool } from "../../utils/dev_tools";
import classNames from "classnames";
import Faqs from "./faq";
import CreatorDrawer from "./creator_drawer";

const faqs = [
  {
    title: "Why are these creators only a sample or close match?",
    body: "The creators shown here are close matches based on your initial preferences. As you finalize your campaign brief and budget, and once creators opt in to your campaign, we’ll refine the matches to find the most relevant creators for your goals.",
  },
  {
    title:
      "Will I be able to see more creator options after setting my campaign details?",
    body: "Yes, once your campaign brief and budget are entered, our AI will match you with cohorts of the best creators, fully opted in and negotiated for the fairest market value. These AI-matched creators will be ready to work and available for selection at the campaign package stage.",
  },
  {
    title: "How does Xrossworld match creators to my campaign?",
    body: "Xrossworld’s AI uses your ideal creator profile, campaign goals, and budget to match the best creators for your campaign. The more details you provide, the more precise your matches will be.",
  },
];

const CreatorsDiscovered = () => {
  useTemplate2();
  useDevTool(clickPrimaryButton);
  const [viewCreator, setViewCreator] = useState<any | undefined>();
  const uuid = location.pathname.split("/")[2];
  const [loaded, setLoaded] = useState(false);
  const [animatedCount, setAnimatedCount] = useState(0);
  const { data, loading } = useApiGet(`/campaigns/${uuid}/creator_matches`);

  const matches = data?.matches;

  useEffect(() => {
    if (matches === undefined) {
      return;
    }
    if (matches === 0) {
      setLoaded(true);
    }

    const time = 1500;
    const rate = 27;

    const interval = setInterval(() => {
      setAnimatedCount((c) => {
        if (c >= matches - rate) {
          clearInterval(interval);
          setTimeout(() => setLoaded(true), 500);
          return matches;
        }
        return c + Math.ceil(matches / (time / rate));
      });
    }, rate);

    return () => clearInterval(interval);
  }, [matches]);

  if (matches === undefined) {
    return null;
  }

  return (
    <>
      {!loaded && (
        <div
          className="modal show d-block"
          id="modal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header border-bottom-0" />
              <div className="modal-body">
                <div className="text-center">
                  <i className="bi bi-person-bounding-box fs-1"></i>
                  <h4>Matching Creators</h4>
                  <h5 className="fw-normal pb-5">
                    <strong className="fs-3 text-success" id="totalMatches">
                      {animatedCount}
                    </strong>{" "}
                    Creators Discovered!
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {loaded && (
        <div className="container container-narrow">
          {data?.matches > 0 && (
            <>
              <div className="mt-5 mb-13 text-center">
                <div style={{ color: "#9AB3EC" }}>
                  <h1 className="fs-96 fw-600">{matches.toLocaleString()} Creators</h1>
                  <h3 className="fw-300 mt-2">Matched for Your Campaign</h3>
                </div>
                <p className="mt-2">
                  Creators matched based on your preferences. Now, let's define your
                  campaign goals to move forward.
                </p>
              </div>

              {data?.creators.length > 0 && (
                <div className="wrapper py-3mt-13">
                  <h2 className="fs-2r fw-400 text-center ">
                    Initial Sample of Matched Creators
                  </h2>
                  <p className="text-center mt-2">
                    This is a sample of early matches. Better results will follow after
                    setting your budget and campaign details.
                  </p>

                  <div className="row row-cols-1 row-cols-md-3 g-3 my-3">
                    {data?.creators.map((creator) => (
                      <div className="col" key={creator.handle}>
                        <div
                          className="card matched-creator h-100"
                          onClick={() => setViewCreator(creator)}
                        >
                          <div className="card-body text-center">
                            <div className="text-center">
                              <PlatformIcon
                                platform={creator.platform}
                                className="fs-18"
                                handle={creator.handle}
                                contrast
                              />
                            </div>
                            <div className="mt-3 fs-18">
                              {creator.followers.toLocaleString()} followers
                            </div>
                            <div className="mt-2">
                              <span
                                className={classNames("badge fs-14", {
                                  "text-bg-warning":
                                    creator.size === "celebrity" ||
                                    creator.size === "mega",
                                  "text-bg-light": creator.size === "mid-tier",
                                  "text-bg-secondary":
                                    creator.size === "micro" || creator.size === "nano",
                                })}
                              >
                                {creator.size === "celebrity" && (
                                  <i className="bi bi-star mr-1" />
                                )}
                                {creator.size.toUpperCase()}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}

          <div className="row">
            <div className="col my-4">
              <h2 className="text-center fs-2r fw-400">What's Next?</h2>

              <div className="wrapper-whats-next mt-2">
                <div className="next-step d-flex align-items-center">
                  <img src="/images/icon-brief.png" />
                  <div className="copy d-inline-block">
                    <h4 className="mb-2">Create Campaign Brief</h4>
                    <p>
                      Now, define your campaign’s goals, message, and key details. This
                      will guide the next steps.
                    </p>
                  </div>
                </div>

                <div className="next-step d-flex align-items-center">
                  <img src="/images/icon-adspend.png" />
                  <div className="copy d-inline-block">
                    <h4 className="mb-2">Set your creator ad budget</h4>
                    <p>
                      Enter your budget to see how your campaign will perform across key
                      metrics.
                    </p>
                  </div>
                </div>

                <div className="next-step d-flex align-items-center">
                  <img src="/images/icon-negotiate.png" />
                  <div className="copy d-inline-block">
                    <div className="d-flex align-items-center">
                      <div className="d-flex">
                        <h4 className="mb-2">Negotiate with Creators</h4>
                      </div>
                      <span className="badge text-bg-info">
                        Xrossworld's Responsibility
                      </span>
                    </div>
                    <p>
                      Our AI will handle negotiations once your campaign goals and spend
                      are confirmed.
                    </p>
                  </div>
                </div>

                <div className="next-step d-flex align-items-center">
                  <img src="/images/icon-package.png" />
                  <div className="copy d-inline-block">
                    <div className="d-flex align-items-center">
                      <div className="d-flex">
                        <h4 className="mb-2">Choose a Campaign Package</h4>
                      </div>
                    </div>
                    <p>
                      After setting your goals and budget, select from tailored campaign
                      packages.
                    </p>
                  </div>
                </div>

                <div className="next-step d-flex align-items-center border-0">
                  <img src="/images/icon-launch.png" />
                  <div className="copy d-inline-block">
                    <div className="d-flex align-items-center">
                      <div className="d-flex">
                        <h4 className="mb-2">Campaign Launch</h4>
                      </div>
                      <span className="badge text-bg-info">
                        Xrossworld's Responsibility
                      </span>
                    </div>
                    <p>
                      Once you've selected a campaign package, we’ll notify your creators
                      to begin. You’ll be able to monitor content creation and ensure
                      everything is ready for launch.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="page-actions mt-5">
            <div>
              <Link
                className="btn btn-xw-secondary btn-lg"
                to={`/campaigns/${uuid}/creator_profile`}
              >
                Back
              </Link>
              <Link
                className="btn btn-xw-primary btn-lg"
                to={`/campaigns/${uuid}/campaign_brief`}
              >
                Continue to Campaign Setup
              </Link>
            </div>
            <div className="mt-4">
              <Link to="/campaigns">Save and Exit</Link>
            </div>
          </div>

          <Faqs faqs={faqs} />
          <CreatorDrawer
            viewCreatorPlatform={viewCreator}
            onHide={() => setViewCreator(undefined)}
          />
        </div>
      )}
    </>
  );
};

export default CreatorsDiscovered;
