import { Link, useNavigate } from "react-router-dom";
import { useApiMutation, usePageData } from "../../utils/api";
import { formatNummber } from "../../utils/helpers";
import { Alert } from "react-bootstrap";
import { TextField } from "../campaigns/fields";
import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { SubmitButton } from "../../utils/submit_button";
import { genericError } from "../../utils/errors";
import { formatPlatform, PlatformIcon } from "../campaigns/utils";

const Offer = () => {
  const { data, unavailable } = usePageData();
  const navigate = useNavigate();
  const { post, saving } = useApiMutation();
  const formik = useFormik({
    initialValues: { counter_offer: null },
    validationSchema: Yup.object({
      counter_offer: Yup.number().required("Required").min(1, "Required"),
    }),
    onSubmit: (values) => {
      post(`${deal._link}/set_counter_offer`, {
        counter_offer: values.counter_offer,
      }).then(({ data }) => {
        if (data.success) {
          navigate("/creators");
        } else {
          genericError();
        }
      });
    },
  });

  if (unavailable) {
    return unavailable;
  }

  const { deal } = data;
  const { campaign, creator_platform } = deal;
  const unconnected = !creator_platform.connected;

  return (
    <div>
      <h2>{campaign.name}</h2>
      <div>{campaign?.agency?.name}</div>

      {unconnected && (
        <Alert variant="warning" className="mt-4">
          <div className="fw-600">Social media account not connected.</div>
          <p>
            Please{" "}
            <Link to="/creators/creator_platforms">
              connect your {formatPlatform(creator_platform.platform)} account
            </Link>
            <PlatformIcon object={creator_platform} className="ml-1" /> to continue.
          </p>
        </Alert>
      )}

      <form onSubmit={formik.handleSubmit} className="mt-4">
        <label className="fs-20">Set your campaign price</label>
        <div className="flex align-items-center">
          <TextField
            name="counter_offer"
            type="number"
            disabled={unconnected}
            formik={formik}
            inputClassName="fs-36"
            prepend={<div className="fs-36">$</div>}
          />
        </div>
        <div className="fs-13 gray mt-1">Tap to manually input price</div>

        <SubmitButton
          className="btn btn-primary w-100 mt-6"
          type="submit"
          disabled={unconnected}
          loading={saving}
        >
          Submit proposal
        </SubmitButton>
      </form>

      <Link to="/creators" className="mt-3 d-block text-center">
        Back to Dashboard
      </Link>
    </div>
  );
};

export default Offer;
