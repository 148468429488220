import { useState } from "react";
import { usePageData } from "../../utils/api";
import Faqs from "../campaigns/faq";
import { PlatformIcon, ProgressCircle, SizeBadge } from "../campaigns/utils";
import { Link } from "react-router-dom";
import { last } from "lodash";
import TabbedCreatorDrawer from "../campaigns/tabbed_creator_drawer";
import classNames from "classnames";

const faqs = [
  {
    title: "Can I swap creators in this package?",
    body: "No, creators are pre-selected based on their alignment with your campaign goals and the ideal creator profile you've set. However, if a creator doesn't complete their commitment, we will automatically replace them with a similar match at no extra cost.",
  },
  {
    title: "What data is collected from creators to match them with my campaign?",
    body: "We analyze a combination of first-party data from creators' social media profiles—such as audience demographics, engagement rates, content performance, and follower growth—along with their interests and previous campaign results. Our AI uses this data to ensure the selected creators align with your campaign goals, target audience, and budget for the best match.",
  },
  {
    title: "What are the different creator types, and how do they affect my campaign?",
    body: "Creators are categorized into types based on their follower count and influence: Nano, Micro, Mid-tier, and Mega. Nano creators have smaller, niche audiences but higher engagement rates, while Mega creators have a massive reach but tend to have lower engagement per follower. Each type plays a unique role in your campaign, offering a balance between audience size, engagement, and cost.",
  },
];

const Package = () => {
  const { data, unavailable } = usePageData();
  const [viewCreator, setViewCreator] = useState<any | undefined>(undefined);
  const [selectedTier, setSelectedTier] = useState<any>("all");

  if (unavailable) {
    return unavailable;
  }

  const { package: pkg } = data;

  const readOnly = !!pkg.campaign.selected_package_id;

  const prevPkg =
    pkg.siblings.find((s) => s.index === pkg.index - 1) || last(pkg.siblings);
  const nextPkg = pkg.siblings.find((s) => s.index === pkg.index + 1) || pkg.siblings[0];

  let featuredMetrics = [
    { label: "Plays", value: pkg.impression_range, icon: "play" },
    { label: "Interactions", value: pkg.interaction_range, icon: "heart" },
    { label: "Engagement", value: pkg.engagement_rate, icon: "hand-thumbs-up" },
    { label: "Total Creators", value: pkg.creator_platforms.length, icon: "people" },
    { label: "Total Content", value: pkg.creator_platforms.length, icon: "camera-reels" },
  ];

  let cardData = [
    { title: "Following", value: pkg.total_followers },
    {
      title: "Gender",
      value: pkg.top_gender.percentage,
      subtext: pkg.top_gender.display,
      type: "percent",
    },
    {
      title: "Age Group",
      value: pkg.top_age_range.percentage,
      subtext: pkg.top_age_range.display,
      type: "percent",
    },
  ];

  let filterButtons = [
    { id: "all", label: "All", count: pkg.creator_platforms.length, primary: true },
    { id: "celebrity", label: "Celebrity", count: 0 },
    { id: "mega", label: "Mega", count: 0 },
    { id: "mid-tier", label: "Mid-tier", count: 0 },
    { id: "micro", label: "Micro", count: 0 },
    { id: "nano", label: "Nano", count: 0 },
  ];

  // Update button counts
  pkg.size_counts.forEach((size) => {
    const button = filterButtons.find((button) => button.id === size.name);
    if (button) {
      button.count = size.count;
    }
  });

  return (
    <div id="main" className="container pb-10">
      <div className="row my-5 pb-3 border-bottom">
        <div className="col">
          <div className="text-center fw-400 mb-1r">
            <a href="/campaigns">{pkg.campaign.name}</a>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="flex-1">
              {!readOnly && prevPkg && (
                <Link to={prevPkg._link} className="text-decoration-none">
                  <i className="bi bi-chevron-left" /> View Package {prevPkg.index}
                </Link>
              )}
            </div>
            <div className="flex-1">
              <h1 className="text-center">Package {pkg.index}</h1>
              <h2 className="text-center mx-5 mt-1 px-5 fs-32">
                {parseFloat(pkg.cost).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </h2>
            </div>
            <div className="flex-1 text-right">
              {!readOnly && nextPkg && (
                <Link to={nextPkg._link} className="text-decoration-none">
                  View Package {nextPkg.index}
                  <i className="bi bi-chevron-right" />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h3 className="text-center mb-5">
            Breakdown of Creators, Content, and Predicted Outcomes
          </h3>

          <div id="section-featured-metrics" className="mb-5">
            <h6 className="text-body-tertiary fw-light mb-1">FEATURED METRICS</h6>
            <div className="row row-cols-1 row-cols-md-5 g-3">
              {featuredMetrics.map((metric) => (
                <div className="col" key={metric.label}>
                  <div className="card h-100 bg-secondary py-2">
                    <div className="card-body">
                      <i className={`bi bi-${metric.icon} d-block fs-4`} />
                      {metric.label}
                      <h2 className="mt-1 mb-0 fs-28 white">{metric.value}</h2>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="row">
            <div className="col col-4">
              <div id="section-audience" className="mb-5">
                <h6 className="text-body-tertiary fw-light mb-1">
                  CREATOR AGGREGATED AUDIENCE
                </h6>
                <div className="row row-cols-1 row-cols-md-1 g-2">
                  {cardData.map((card, index) => (
                    <div key={index} className="col">
                      <div className="card h-100 bg-dark">
                        <div className="card-body">
                          {card.type === "percent" && (
                            <ProgressCircle
                              value={card.value as number}
                              className="float-end"
                            />
                          )}
                          {card.title}
                          <h4 className="mt-1 mb-0">
                            {card.value}
                            {card.type === "percent" && "%"}
                            {card.subtext && (
                              <span className="text-body-tertiary fs-6">
                                {" "}
                                {card.subtext}
                              </span>
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col col-8">
              <div id="section-interests" className="mb-5">
                <h6 className="text-body-tertiary fw-light mb-1">AGGREGATED INTERESTS</h6>
                <div className="row row-cols-1 row-cols-md-2 g-3">
                  {pkg.top_interests.map((interest, index) => (
                    <div className="col" key={index}>
                      <div className="card h-100 bg-dark px-2">
                        <div className="card-body">
                          <ProgressCircle
                            value={interest.percentage}
                            size="lg"
                            className="float-end"
                          />
                          {interest.name}
                          <h3 className="mt-1 mb-0">
                            {parseInt(interest.percentage, 10)}
                            {"%"}
                          </h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div id="section-creators" className="mb-5">
            <h6 className="text-body-tertiary fw-light">PACKAGE CREATORS</h6>

            <div className="filter my-4">
              {filterButtons.map((button) => (
                <button
                  key={button.id}
                  id={button.id}
                  className={`btn btn-sm ${
                    button.id === selectedTier ? "btn-primary" : "btn-outline-secondary"
                  } mx-1`}
                  data-ol-has-click-handler=""
                  onClick={() => setSelectedTier(button.id)}
                >
                  {button.label} {button.id !== "all" && `(${button.count})`}
                  {button.id === "all" && (
                    <span className="fw-light">({button.count})</span>
                  )}
                </button>
              ))}
            </div>

            <table id="tbl_creators" className="table align-middle table-hover">
              <thead>
                <tr data-ol-has-click-handler="">
                  <th>Creator</th>
                  <th>Handle</th>
                  <th className="text-end pe-5">
                    <i className="bi bi-caret-down-fill me-1" /> Followers
                  </th>
                  <th>Creator Type</th>
                  <th>Top Audience Gender</th>
                  <th className="text-end pe-5">Avg Impressions</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {pkg.creator_platforms
                  .filter((p) => selectedTier === "all" || p.size === selectedTier)
                  .map((p) => (
                    <tr
                      className={classNames(p.size, "pointer")}
                      onClick={() => setViewCreator(p)}
                    >
                      <td>{p.creator.name}</td>
                      <td>
                        <PlatformIcon platform={p.platform} contrast />
                        <span className="handle ml-1">{p.handle}</span>
                      </td>
                      <td className="text-end pe-5">{p.followers?.toLocaleString()}</td>
                      <td>
                        <SizeBadge size={p.size} />
                      </td>
                      <td>
                        {p.top_gender_percentage != null && p.top_gender
                          ? `${p.top_gender_percentage}% ${p.top_gender}`
                          : ""}
                      </td>
                      <td className="text-end pe-5">{p.impressions?.toLocaleString()}</td>
                      <td className="text-end pe-3">
                        <div className="link-like text-decoration-none fs-14">
                          View <i className="bi bi-chevron-right" />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row row-cta">
        <div className="col">
          <div className="page-actions mt-2">
            {!readOnly && (
              <div className="cta">
                <a href={`${pkg._link}/checkout`} className="btn btn-xw-primary btn-lg">
                  Select Package {pkg.index}
                </a>
              </div>
            )}
            {readOnly ? (
              <Link to="/campaigns" className="btn btn-xw-secondary margin-auto">
                Back
              </Link>
            ) : (
              <Link to={`/campaigns/${pkg.campaign.uuid}`} className="d-block mt-4">
                Back to all Packages
              </Link>
            )}
          </div>
        </div>
      </div>
      <Faqs faqs={faqs} />
      <TabbedCreatorDrawer
        viewCreator={viewCreator}
        onHide={() => setViewCreator(undefined)}
        defaultPlatform={pkg.campaign.platform}
      />
    </div>
  );
};

export default Package;
