import { Link } from "react-router-dom";
import { dealStatusBadge } from "../../utils/deal_status";

const CampaignCard = ({ deal }) => {
  return (
    <Link to={deal._link} className="border p-4 d-block mv-2">
      <div className="flex space-between align-items-center">
        <div>
          <h2 className="mt-1">{deal.campaign.name}</h2>
        </div>
        <div>
          <i className="bi bi-chevron-right fs-24" />
        </div>
      </div>
    </Link>
  );
};

export default CampaignCard;
