import Header from "./header";
import CampaignInfo from "./steps_side_nav";
import { useCampaign, useShowOnboardingNav } from "./utils";
import { CheckboxGroup, MultiSelect, RadioGroup, TextArea, Validators } from "./fields";
import ActionButtons from "./action_buttons";
import Tooltip from "./tooltip";
import { HelpLinkDrawer } from "../../utils/offcanvas";
import { useFormikHandler } from "./form_handler";
import { Fragment } from "react/jsx-runtime";

export const CALL_TO_ACTIONS = [
  {
    value: "Link in bio",
    description:
      "Direct followers to a link provided in the influencer's bio for more information or purchase.",
  },
  {
    value: "Amazon affiliate link",
    description:
      "Use a specific Amazon link to promote a product and earn a commission on sales.",
  },
  {
    value: "Custom affiliate link",
    description: "Use a unique link to track sales or leads generated by the influencer.",
  },
  {
    value: "Share promo code",
    description:
      "Provide a discount code that followers can use for a special offer or deal.",
  },
  {
    value: "Follow us",
    description: "Encourage followers to follow the brand's social media account.",
  },
  {
    value: "Click the link",
    description: "Encourage followers to click on a link to learn more.",
  },
  {
    value: "Sign up",
    description: "Direct followers to sign up for a newsletter, service, or event.",
  },
  {
    value: "Download",
    description: "Prompt followers to download an app or a digital resource.",
  },
  {
    value: "Watch now",
    description: "Encourage followers to watch a video or livestream.",
  },
  {
    value: "TikTok Shop link",
    description: "Direct followers to a Tik Tok shop.",
  },
  {
    value: "Visit website",
    description: "Prompt followers to visit the brand's website.",
  },
  {
    value: "Enter giveaway",
    description: "Encourage followers to participate in a contest or giveaway.",
  },
  {
    value: "DM for details",
    description: "Invite followers to send a direct message for more information.",
  },
  {
    value: "Save this post",
    description: "Ask followers to save the post for future reference.",
  },
  {
    value: "Share this post",
    description: "Ask followers to share this post with their friends.",
  },
  {
    value: "Comment below",
    description: "Engage followers by asking them to comment on the post",
  },
  {
    value: "No CTA - Brand Awareness Only",
    description:
      "Focus solely on increasing brand visibility without any direct call-to-action.",
  },
].map((o) => ({
  value: o.value,
  description: o.description,
  label: (
    <>
      {o.value}
      <Tooltip className="link-like" tip={o.description}>
        <i className="bi bi-question-circle small text-muted ml-1" />
      </Tooltip>
    </>
  ),
}));

const DISCLOSURES = [
  [
    "Paid partnership label",
    "A label provided by social media platforms to indicate a sponsored collaboration, visible to viewers.",
  ],
  [
    "Collaborator tag",
    "Tagging the brand or partner directly in the post or story, indicating a collaboration.",
  ],
  [
    "Disclosure in caption (i.e. #partner or #sponsored)",
    "Including a disclosure hashtag (e.g., #partner or #sponsored) in the post caption to indicate a paid partnership.",
  ],
  [
    "Verbal disclosure in video",
    "Verbally disclosing the partnership at the beginning or end of a video post.",
  ],
  [
    "Not required",
    "Creators may choose to include such disclosures at their own discretion. XrossWorld is not liable for any penalties resulting from undisclosed promotional content.",
  ],
].map((o) => ({
  value: o[0],
  label: (
    <>
      {o[0]}
      <Tooltip className="link-like" tip={o[1]}>
        <i className="bi bi-question-circle small text-muted ml-1" />
      </Tooltip>
    </>
  ),
}));

const Guidelines = () => {
  const points = [
    "Do not use profanity",
    "Align with brand values",
    "Avoid controversy",
    "Use high-quality visuals",
    "Use clear, error-free captions",
    "Follow platform guidelines",
    "Include a clear CTA if required",
    "Obtain permission for all copyright materials",
    "Ensure authenticity and clarify",
    "Avoid overly promotionial language",
    "Use audience-friendly language",
    "Disclose sponsorships",
  ];
  return (
    <div>
      <div className="mb-4">
        We'll notify all eligible creators to abide by our general guidelines to
        participate in any campaign.
      </div>

      {points.map((p) => (
        <div key={p}>
          <i className="bi bi-check-lg green mr-2" />
          {p}
        </div>
      ))}
    </div>
  );
};

export const CREATOR_OBJECTIVES = [
  {
    value: "Sponsored Post or Content",
    description:
      "A piece of content (image, video, or text) where the influencer promotes a product or service, typically including hashtags and a call-to-action.",
  },
  {
    value: "Short-Form Video",
    description:
      "Engaging, quick video content (15-90 seconds) designed to showcase a product or participate in trends. Works well for product demos, challenges, or awareness campaigns.",
  },
  {
    value: "Long-Form Video Content",
    description:
      "In-depth video content (5-20 minutes) focused on product reviews, tutorials, or detailed storytelling, providing a comprehensive look at the brand.",
  },
  {
    value: "Stories or Temporary Content",
    description:
      "Short-lived, real-time content that disappears after 24 hours, perfect for showcasing behind-the-scenes moments, quick promotions, or product demos.",
  },
  {
    value: "Livestreams",
    description:
      "Real-time video or audio broadcasts where influencers engage directly with their audience through Q&A, product demos, or event coverage.",
  },
  {
    value: "Action-Oriented Content",
    description:
      "Influencers create content encouraging followers to attend an event or visit a business location. This can include:\n• Event Promotion: Promoting physical or virtual events.\n• Location Visit: Driving foot traffic to a business with in-person content.\n• Livestream Event Coverage: Real-time event broadcasting with audience interaction.\n\nExample: A series of Instagram Stories or TikTok videos promoting the event or location, possibly with a discount code or special offer.",
  },
  {
    value: "User-Generated Content (UGC)",
    description:
      "Influencers encourage followers to create and share content related to a campaign, increasing reach and engagement. This content can be reshared by the brand to amplify visibility.",
  },
];

const CreatorObjectiveHelp = () => {
  return (
    <>
      <dl>
        {CREATOR_OBJECTIVES.map(({ value, description }) => (
          <Fragment key={value}>
            <dt>{value}:</dt>
            <dd>{description}</dd>
          </Fragment>
        ))}
      </dl>
    </>
  );
};

const CreatorBrief = () => {
  useShowOnboardingNav();
  const { campaign, unavailable } = useCampaign();

  const formik = useFormikHandler({
    campaign,
    fields: [
      "campaign_purposes",
      "talking_points",
      "hashtags",
      "required_actions",
      "add_ons",
      "partnership_disclosures",
      "additional_guidelines",
      "creator_sample",
      "additional_instructions",
      "creator_sample_return",
    ],
    validators: {
      campaign_purposes: Validators.atLeastOneInGroup,
      talking_points: Validators.required,
      hashtags: Validators.required,
      required_actions: Validators.required,
      add_ons: Validators.atLeastOneInGroup,
      partnership_disclosures: Validators.atLeastOneInGroup,
      creator_sample: Validators.requiredRadio,
      // additional_instructions: Validators.required,
      // creator_sample_return: Validators.requiredRadio,
    },
  });

  if (unavailable) {
    return unavailable;
  }

  return (
    <div id="main" className="container">
      <Header percent={65} />
      <CampaignInfo campaign={campaign} />

      <div className="row">
        <div className="col">
          <div className="page-subhead mb-4">
            <h1>Set Up Your Creator Brief</h1>
            <h2>
              Provide essential details to guide your creators for campaign success.
            </h2>
          </div>

          <form onSubmit={formik.handleSubmit} className="form-container">
            <label>Creator's Objective</label>
            <div className="form-text">
              Outline the key task(s) for the creator. Keep it simple for the best
              results.{" "}
              <HelpLinkDrawer
                title="About Creator's Objectives"
                text="About creator's objectives"
                content={<CreatorObjectiveHelp />}
                closeButton
              />
            </div>
            <MultiSelect
              options={CREATOR_OBJECTIVES.map((obj) => obj.value)}
              name="campaign_purposes"
              formik={formik}
            />

            <TextArea
              label="Talking points"
              sublabel="List the key messages or talking points the creator should cover in their content."
              name="talking_points"
              formik={formik}
              className="mt-4"
              placeholder="Unique Features: Discuss the standout features of the product, such as its durability or ease of use."
            />

            <TextArea
              label="Campaign hashtags"
              sublabel="Add key hashtags to enhance reach and engagement. Avoid using too many, as it may limit the campaign’s effectiveness."
              name="hashtags"
              className="mt-4"
              formik={formik}
            />

            <TextArea
              label="Key Tasks for Creators"
              sublabel="List essential tasks the creator needs to complete. Fewer, focused actions often lead to better results."
              name="required_actions"
              placeholder="Create a Product Review Video: Task: Film a 60-second video reviewing the product, highlighting its key features and benefits."
              className="mt-4"
              formik={formik}
            />

            <CheckboxGroup
              label="Call to action"
              sublabel="Additional features you would like to be included by the creator"
              name="add_ons"
              options={CALL_TO_ACTIONS}
              className="mt-4"
              formik={formik}
            />

            <CheckboxGroup
              label="Partnership disclosures"
              className="mt-4"
              sublabel="How would you like the creators to disclose the paid partnership?"
              name="partnership_disclosures"
              options={DISCLOSURES}
              formik={formik}
            />

            <TextArea
              label="Guidelines"
              sublabel={
                <>
                  We've got you covered with our{" "}
                  <HelpLinkDrawer
                    title="General Guidelines"
                    text="general guidelines"
                    append=". Add any additional guidelines below."
                    content={<Guidelines />}
                  />
                </>
              }
              name="additional_guidelines"
              formik={formik}
              className="mt-4"
            />

            <RadioGroup
              label="Will the Creators be receiving a product sample to demonstrate and complete this campaign?"
              name="creator_sample"
              options={["Yes", "No"]}
              formik={formik}
              className="mt-4"
            />

            {formik.values.creator_sample === "Yes" && (
              <>
                <TextArea
                  label="Product Preferences"
                  sublabel="What information do you need from creators to provide them with product"
                  name="additional_instructions"
                  placeholder="i.e. T-shirt size, select preferred product sample, or provide context for your post"
                  formik={formik}
                  className="mt-4"
                />
                <RadioGroup
                  label="Do you need this product back from the creator?"
                  name="creator_sample_return"
                  options={["Yes", "No"]}
                  formik={formik}
                  className="mt-4"
                />
              </>
            )}

            <ActionButtons saving={formik.saving} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreatorBrief;
