import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Drawer } from "./offcanvas";

export const makeModal = (Component) => {
  const [show, set] = useState<boolean | any>(false);

  const instance = (props) =>
    show && <Component {...props} object={show} close={(_) => set(false)} />;

  instance.set = set;
  instance.show = () => set(true);
  instance.hide = () => set(false);
  instance.object = show;

  return instance;
};

export const ModalBase = ({
  title,
  children,
  close,
  className,
}: {
  title: string | JSX.Element;
  children: JSX.Element | JSX.Element[];
  close: () => void;
  className?: string;
}) => (
  <Modal show onHide={close} className={className}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    {children}
  </Modal>
);

export const DrawerBase = ({ title, children, close }) => (
  <Drawer show onHide={close} title={title}>
    {children}
  </Drawer>
);
