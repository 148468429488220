import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

const w = window as any;

export const Drawer = ({
  title,
  children,
  show,
  onHide,
  trigger,
  placement,
  closeButton,
}: {
  title?: string;
  children: string | JSX.Element;
  show?: boolean;
  onHide?: () => void;
  trigger?: string | JSX.Element;
  placement?: "start" | "end" | "bottom" | "top";
  closeButton?: boolean;
}) => {
  const [selfShow, setSelfShow] = useState(false);

  let computedShow;
  let computedHide;

  if (trigger) {
    computedShow = selfShow;
    computedHide = computedHide = () => setSelfShow(false);
  } else {
    computedShow = show;
    computedHide = onHide;
  }

  return (
    <>
      {trigger && <span onClick={() => setSelfShow(true)}>{trigger}</span>}
      <Offcanvas
        show={computedShow}
        onHide={computedHide}
        placement={placement || "end"}
        className="offcanvas-larger"
      >
        {computedShow && (
          <>
            <Offcanvas.Header closeButton className="align-items-start">
              <Offcanvas.Title>{title}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {children}
              {closeButton && (
                <div className="float-end my-4">
                  <button
                    type="button"
                    data-bs-dismiss="offcanvas"
                    className="btn btn-outline-primary"
                    onClick={onHide}
                  >
                    Close
                  </button>
                </div>
              )}
            </Offcanvas.Body>
          </>
        )}
      </Offcanvas>
    </>
  );
};

export const HelpLinkDrawer = ({
  text,
  append,
  content,
  title,
  closeButton,
}: {
  text: string | JSX.Element;
  append?: string | JSX.Element;
  content: JSX.Element;
  title: string;
  closeButton?: boolean;
}) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <a className="pointer" onClick={() => setShow(true)}>
        {text}
      </a>
      {append}
      <Drawer
        title={title}
        show={show}
        onHide={() => setShow(false)}
        closeButton={closeButton}
      >
        {content}
      </Drawer>
    </>
  );
};
