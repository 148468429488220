import { useApiMutation, usePageData } from "../../../utils/api";
import { formatDate, PlatformIcon, SizeBadge } from "../../campaigns/utils";
import { Breadcrumb, Dropdown, Tab, Tabs } from "react-bootstrap";
import { DataTableLocal } from "../data_table";
import { EllipsisMenu } from "../../../utils/ellipsis_menu";
import { useEffect, useState } from "react";
import Form from "./form";
import PlatformForm from "./platform_form";
import { genericError } from "../../../utils/errors";
import { toast } from "react-toastify";

const ViewCreator = () => {
  const { creator, unavailable, refetch } = usePageData("creator");
  const [formObject, setFormObject] = useState<{ uuid?: string } | undefined>(undefined);
  const [platformObject, setPlatformObject] = useState<{ uuid?: string } | undefined>(
    undefined
  );
  const { apiDelete } = useApiMutation();

  useEffect(() => {
    if (creator && creator.creator_platforms.length === 0) {
      setPlatformObject({});
    }
  }, [creator]);

  const deleteHandle = (handle) => {
    if (confirm("Delete handle?")) {
      apiDelete(handle._link).then(({ data }) => {
        if (data.success) {
          refetch();
          toast("Handle was deleted.");
        } else {
          genericError();
        }
      });
    }
  };

  if (unavailable) {
    return unavailable;
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item href="/admin/creators">Creators</Breadcrumb.Item>
        <Breadcrumb.Item active>Creator Detail</Breadcrumb.Item>
      </Breadcrumb>

      <div className="flex space-between align-items-center">
        <h1 className="flex align-items-center">
          <div className="fs-40 fw-400">{creator.name}</div>
        </h1>
        <div>
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary btn-sm">Actions</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setFormObject(creator)}>Edit</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="mt-2">
        {creator.creator_platforms.map((p, i) => (
          <a
            href={p.link}
            target="_blank"
            className="handleLink4 btn btn-light btn-sm mh-2"
            key={i}
          >
            <PlatformIcon platform={p.platform} className="mr-1" />
            {p.handle}
          </a>
        ))}
      </div>

      <Tabs className="mt-4">
        <Tab eventKey={`handles`} title="Handles">
          <button
            className="btn btn-sm btn-outline-primary mt-4 mb-3"
            onClick={() => setPlatformObject({})}
          >
            Add Handle
          </button>

          <DataTableLocal
            schema={[
              {
                name: "handle",
                value: (p) => (
                  <>
                    <PlatformIcon platform={p.platform} />
                    <a href={p.link} target="_blank" className="ml-2">
                      {p.handle}
                      <i className="bi bi-box-arrow-up-right gray ml-1 fs-10 vertical-align-middle" />
                    </a>
                  </>
                ),
              },
              { name: "size", value: (p) => <SizeBadge size={p.size} /> },
              { name: "categories", value: "categories" },
              { name: "followers", type: "number" },
              {
                name: "predicted impressions",
                value: (p) => p.impressions,
                type: "number",
              },
              {
                name: "predicted interactions",
                value: (p) => p.interactions,
                type: "number",
              },
              {
                name: "actions",
                header: "",
                headerClass: "text-right",
                cellClass: "text-right",
                value: (p) => (
                  <>
                    <EllipsisMenu
                      actions={[
                        { label: "Edit", action: () => setPlatformObject(p) },
                        { separator: true },
                        {
                          label: "Delete",
                          action: () => deleteHandle(p),
                          className: "red",
                          disabled: !p["deleteable?"],
                        },
                      ]}
                    />
                  </>
                ),
              },
            ]}
            data={creator.creator_platforms}
            sortable
          />
        </Tab>
        <Tab eventKey={`profile`} title="Profile">
          <div className="description-group mt-6 mb-4">
            <h4 className="fs-24 fw-400 mb-2">Personal Info</h4>
            <table className="table table-striped table-sm">
              <tbody>
                {[
                  { label: "Ethnicity", value: creator.ethnicity },
                  { label: "Gender", value: creator.gender },
                  { label: "Birthdate", value: formatDate(creator.birthdate) },
                  { label: "Age", value: creator.age },
                ].map(({ label, value }) => (
                  <tr key={label}>
                    <td className="fw-600 w-300px">{label}</td>
                    <td>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <h4 className="fs-24 fw-400 mt-6 mb-2">Contact Info</h4>
            <table className="table table-striped table-sm">
              <tbody>
                {[
                  { label: "E-mail", value: creator.email },
                  { label: "Agent's E-mail", value: creator.agent_email },
                  { label: "Phone", value: creator.phone },
                  { label: "City", value: creator.city },
                  { label: "State", value: creator.state },
                  { label: "Country", value: creator.country },
                ].map(({ label, value }) => (
                  <tr key={label}>
                    <td className="fw-600 w-300px">{label}</td>
                    <td>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Tab>
      </Tabs>

      <Form
        creator={formObject}
        close={() => setFormObject(undefined)}
        refetch={refetch}
      />

      <PlatformForm
        object={platformObject}
        parent={creator}
        onSuccess={refetch}
        setObject={(object) => setPlatformObject(object)}
      />
    </div>
  );
};

export default ViewCreator;
