import { useState } from "react";
import { useQuery } from "react-query";
import { categories, platforms } from "../../campaigns/lists";
import { apiGet } from "../../../utils/api";
import DataTable from "../data_table";
import { Link } from "react-router-dom";
import { PlatformIcon, SizeBadge } from "../../campaigns/utils";
import Form from "./form";
import { EllipsisMenu } from "../../../utils/ellipsis_menu";
import { isArray, union } from "lodash";

const emptyFilters = {
  sort: "handle",
  sortDir: "asc",
  page: 1,
};

const genders = [
  { value: "Female", label: "Female" },
  { value: "Male", label: "Male" },
  { value: "Other", label: "Other" },
];

const sizes = [
  { value: "celebrity", label: "Celebrity" },
  { value: "mega", label: "Mega" },
  { value: "mid-tier", label: "Mid-tier" },
  { value: "micro", label: "Micro" },
  { value: "nano", label: "Nano" },
];

const buildParams = (filters: { [key: string]: string | number }) => {
  return Object.entries(filters)
    .filter(([k, v]) => v)
    .map(([k, v]) => (isArray(v) ? v.map((i) => `${k}[]=${i}`).join("&") : `${k}=${v}`))
    .join("&");
};

const AdminCreatorsIndex = () => {
  const [filters, setFilters] = useState(emptyFilters);
  const [formObject, setFormObject] = useState<{ uuid?: string } | undefined>(undefined);

  const { data, refetch, isSuccess, isFetching } = useQuery({
    queryKey: ["data", filters],
    queryFn: () => apiGet(`/admin/creators`, { params: filters }).then((res) => res.data),
    keepPreviousData: true,
  });

  const { results, meta, filter_options } = data || {};

  return (
    <div id="main">
      <div className="flex space-between align-items-center">
        <h1>Manage Creators</h1>
        <button className="btn btn-primary btn-sm" onClick={() => setFormObject({})}>
          Add Creator
        </button>
      </div>

      {isSuccess && (
        <>
          <DataTable
            schema={[
              {
                name: "name",
                value: (p) => (
                  <Link to={`/admin/creators/${p.creator_uuid}`}>
                    {p.name || <span className="red">&lt;no name&gt;</span>}
                  </Link>
                ),
              },
              {
                name: "handle",
                value: (p) => (
                  <>
                    <PlatformIcon platform={p.platform} />
                    <a href={p.link} target="_blank" className="ml-2">
                      {p.handle}
                      <i className="bi bi-box-arrow-up-right gray ml-1 fs-10 vertical-align-middle" />
                    </a>
                  </>
                ),
              },
              { name: "email" },
              { name: "size", value: (p) => <SizeBadge size={p.size} /> },
              { name: "followers", type: "number" },
              { name: "country", value: "country" },
              { name: "categories", value: "categories" },
              { name: "gender", value: "gender" },
              { name: "ethnicity", value: "ethnicity" },
              {
                name: "created",
                value: (p) => new Date(p.created_at).toLocaleDateString(),
              },
              {
                name: "edited",
                value: (p) => new Date(p.updated_at).toLocaleDateString(),
              },
              {
                name: "edit",
                header: (
                  <EllipsisMenu
                    actions={[
                      {
                        label: "Download CSV",
                        link: `/admin/creators/csv?${buildParams(filters)}`,
                        target: "_blank",
                      },
                    ]}
                    horizontal
                  />
                ),
                value: (p) => (
                  <EllipsisMenu
                    actions={[
                      {
                        label: "View Profile",
                        link: `/admin/creators/${p.creator_uuid}`,
                      },
                      {
                        label: "Edit Profile",
                        action: () => setFormObject({ uuid: p.creator_uuid }),
                      },
                    ]}
                  />
                ),
                sortable: false,
              },
            ]}
            data={results}
            filterControls={[
              { name: "search", type: "search" },
              { name: "platform", options: platforms },
              {
                name: "has_email",
                placeholder: "Has Email",
                options: [
                  { value: "true", label: "Yes" },
                  { value: "false", label: "No" },
                ],
              },
              { name: "country", options: filter_options.country },
              {
                name: "categories",
                options: union(filter_options.categories, categories),
              },
              { name: "gender", options: genders },
              { name: "ethnicity", options: filter_options.ethnicity },
              { name: "size", options: sizes },
            ]}
            filters={filters}
            setFilters={setFilters}
            reset={() => setFilters(emptyFilters)}
            sortable
            meta={meta}
            widths={[150, 200, 125, 125, 150, null, 100, 100, 100, 100, 50]}
            isFetching={isFetching}
          />
        </>
      )}

      <Form
        creator={formObject}
        close={() => setFormObject(undefined)}
        refetch={refetch}
      />
    </div>
  );
};

export default AdminCreatorsIndex;
