import { SizeBadge } from "./utils";

const CreatorPlatformProfile = ({ platform }: { platform: any }) => {
  return (
    <ul className="list-group list-group-flush">
      <li className="list-group-item border-0 mt-2">
        <h6>Handle</h6>
        <a href={platform.link} className="" target="_blank" rel="noopener noreferrer">
          Link to Handle <i className="bi bi-box-arrow-up-right small"></i>
        </a>
      </li>

      <li className="list-group-item border-0 mt-2">
        <h6 className="mb-_5r">Size</h6>
        <SizeBadge size={platform.size} />
      </li>

      <li className="list-group-item border-0 mt-2">
        <h6 className="mb-_5r">Interests</h6>
        <div>{platform.categories.join(", ")}</div>
      </li>

      {(platform.followers ||
        platform.impressions ||
        platform.interactions ||
        platform.engagement_rate) && (
        <li className="list-group-item border-0 mt-2">
          <h6 className="mb-_5r">Handle Metrics</h6>
          <div className="row">
            {platform.followers && (
              <div className="col col-6 mb-2">
                <div className="card h-100 bg-dark">
                  <div className="card-body">
                    <h4 className="mt-1 mb-0">{platform.followers.toLocaleString()}</h4>
                    Followers
                  </div>
                </div>
              </div>
            )}
            {platform.engagement_rate && (
              <div className="col col-6 mb-2">
                <div className="card h-100 bg-dark">
                  <div className="card-body">
                    <h4 className="mt-1 mb-0">{platform.engagement_rate}%</h4>
                    Engagement
                  </div>
                </div>
              </div>
            )}
            {platform.impressions && (
              <div className="col col-6 mb-2">
                <div className="card h-100 bg-dark">
                  <div className="card-body">
                    <h4 className="mt-1 mb-0">{platform.impressions.toLocaleString()}</h4>
                    Impressions
                  </div>
                </div>
              </div>
            )}
            {platform.interactions && (
              <div className="col col-6 mb-2">
                <div className="card h-100 bg-dark">
                  <div className="card-body">
                    <h4 className="mt-1 mb-0">
                      {platform.interactions.toLocaleString()}
                    </h4>
                    Interactions
                  </div>
                </div>
              </div>
            )}
          </div>
        </li>
      )}

      {(platform.first_top_age_range ||
        platform.top_gender ||
        platform.first_top_country_name) && (
        <li className="list-group-item border-0 mt-2">
          <h6 className="mb-_5r">Top Audience Metrics</h6>
          <div className="row">
            {platform.first_top_age_range && (
              <div className="col col-6 mb-2">
                <div className="card h-100 bg-dark">
                  <div className="card-body">
                    <img
                      src="https://jamostudio.com/xrossworld/02/images/progress-circle.png"
                      width="40"
                      className="float-end"
                      alt="Progress Circle"
                    />
                    <h4 className="mt-1 mb-0">
                      {platform.first_top_age_range}{" "}
                      <span className="ms-1 text-body-tertiary fs-6">
                        {platform.first_top_age_percentage}%
                      </span>
                    </h4>
                    Top Age Range
                  </div>
                </div>
              </div>
            )}
            {platform.top_gender_percentage && (
              <div className="col col-6 mb-2">
                <div className="card h-100 bg-dark">
                  <div className="card-body">
                    <img
                      src="https://jamostudio.com/xrossworld/02/images/progress-circle.png"
                      width="40"
                      className="float-end"
                      alt="Progress Circle"
                    />
                    <h4 className="mt-1 mb-0">
                      {platform.top_gender}{" "}
                      <span className="ms-1 text-body-tertiary fs-6">
                        {platform.top_gender_percentage}%
                      </span>
                    </h4>
                    Top Gender
                  </div>
                </div>
              </div>
            )}
            {platform.first_top_country_name && (
              <div className="col col-6 mb-2">
                <div className="card h-100 bg-dark">
                  <div className="card-body">
                    <img
                      src="https://jamostudio.com/xrossworld/02/images/progress-circle.png"
                      width="40"
                      className="float-end"
                      alt="Progress Circle"
                    />
                    <h4 className="mt-1 mb-0">
                      {platform.first_top_country_name}{" "}
                      <span className="ms-1 text-body-tertiary fs-6">
                        {platform.first_top_country_percentage}%
                      </span>
                    </h4>
                    Top Location
                  </div>
                </div>
              </div>
            )}
          </div>
        </li>
      )}
    </ul>
  );
};

export default CreatorPlatformProfile;
