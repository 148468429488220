import { Dropdown } from "react-bootstrap";

interface Action {
  label: string | JSX.Element;
  action?: () => void;
  className?: string;
  link?: string;
  target?: "_blank";
  disabled?: boolean;
  if?: boolean;
}
interface Separator {
  separator: true;
  if?: boolean;
}
type MenuItem = Action | Separator;

export const EllipsisMenu = ({
  actions,
  horizontal,
  name,
}: {
  actions: MenuItem[];
  horizontal?: boolean;
  name?: string;
}) => {
  return (
    <Dropdown className="ellipsis-menu">
      <Dropdown.Toggle variant="sm" id="dropdown-basic" name={name}>
        {horizontal ? (
          <i className="bi bi-three-dots" />
        ) : (
          <i className="bi bi-three-dots-vertical" />
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {actions
          .filter((a) => !a.hasOwnProperty("if") || a.if)
          .map((action, i) =>
            (action as Separator).separator ? (
              <Dropdown.Divider key={i} />
            ) : (
              <Dropdown.Item
                href={(action as Action).link}
                target={(action as Action).target}
                key={i}
                onClick={() => {
                  (action as Action).action?.();
                }}
                className={(action as Action).className}
                style={{ opacity: (action as Action).disabled ? 0.4 : 1 }}
                disabled={(action as Action).disabled}
              >
                {(action as Action).label}
              </Dropdown.Item>
            )
          )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export const ButtonMenu = ({ actions, name }: { actions: Action[]; name?: string }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="sm"
        id="dropdown-basic"
        className="btn btn-outline-secondary"
        name={name}
      >
        Actions
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {actions
          .filter((a) => !a.hasOwnProperty("if") || a.if)
          .map((action, i) => (
            <Dropdown.Item
              href={action.link}
              target={action.target}
              key={i}
              onClick={() => {
                action.action?.();
              }}
              className={action.className}
              disabled={action.disabled}
            >
              {action.label}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
