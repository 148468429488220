import { toast } from "react-toastify";
import { useApiMutation } from "../../../utils/api";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitButton } from "../../../utils/submit_button";
import { z } from "zod";

const schema = z.object({
  followers_max: z.number().min(1).default(10000),
  followers_min: z.number().min(1).default(5000),
  count: z.number().min(1).default(5),
  account: z.string().default("sandbox"),
});

type FormValues = z.infer<typeof schema>;

export const FetchCreatorsForm = ({
  campaign,
  refetch,
}: {
  campaign: any;
  refetch: () => void;
}) => {
  const { post, saving } = useApiMutation();
  const { register, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: schema.parse({}),
  });

  const onSubmit = (values: FormValues) => {
    post(`${campaign._link}/fetch_creators`, values).then(({ data }) => {
      if (data.success) {
        toast.success("Creators fetched");
        refetch();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mxw-500px">
      <div className="row mb-3">
        <div className="col-6">
          <label className="form-label">Amount to Fetch</label>
          <select
            {...register("count", { valueAsNumber: true })}
            className="form-control"
          >
            {[2, 5, 10, 25].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>
        <div className="col-6">
          <label className="form-label">Account</label>
          <select {...register("account")} className="form-control">
            {["sandbox", "staging", "prod"].map((account) => (
              <option key={account} value={account}>
                {account}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6">
          <label className="form-label">Followers Min</label>
          <input
            type="number"
            {...register("followers_min", { valueAsNumber: true })}
            className="form-control"
            required
          />
        </div>
        <div className="col-6">
          <label className="form-label">Followers Max</label>
          <input
            type="number"
            {...register("followers_max", { valueAsNumber: true })}
            className="form-control"
            required
          />
        </div>
      </div>

      <SubmitButton type="submit" loading={saving} className="btn btn-primary mt-3">
        Fetch Creators
      </SubmitButton>
    </form>
  );
};

const FetchCreators = ({ campaign, refetch }: { campaign: any; refetch: () => void }) => {
  return <FetchCreatorsForm campaign={campaign} refetch={refetch} />;
};

export default FetchCreators;
