import { useApiGet, useApiMutation } from "../../../utils/api";
import { campaignUuid } from "../../campaigns/utils";
import { DrawerBase, makeModal } from "../../../utils/make_modal";
import { genericError } from "../../../utils/errors";
import { toast } from "react-toastify";
import { SubmitButton } from "../../../utils/submit_button";

export const useBulkInviteDrawer = () =>
  makeModal(({ refetch, close }: { refetch: () => void; close: () => void }) => {
    const { post, saving } = useApiMutation();
    const { data, unavailable } = useApiGet(
      `/admin/campaigns/${campaignUuid()}/deals/inviteable_counts`
    );

    if (unavailable) {
      return unavailable;
    }

    const send = () => {
      post(`/admin/campaigns/${campaignUuid()}/deals`).then(({ data }) => {
        if (data.success) {
          close();
          refetch();
          toast(`${data.count} creators invited`);
        } else {
          genericError();
        }
      });
    };

    const { inviteable, uninviteable } = data;

    return (
      <DrawerBase title={"Send invite email"} close={close}>
        <div className="alert alert-info">
          Only creators who have an email in the system and is in{" "}
          <strong>"Matched"</strong> status will be sent an invite email.
        </div>
        {inviteable > 0 && (
          <>
            {inviteable.toLocaleString()} matched creators with emails will be sent an
            invite email.
          </>
        )}
        {inviteable === 0 && <>There are currently no matched creators with emails</>}
        {uninviteable > 0 && (
          <div className="red fs-14 mt-1">
            {uninviteable.toLocaleString()} matched creators do not have an email.
          </div>
        )}
        {inviteable > 0 && (
          <div className="mt-8 text-end">
            <button
              className="btn btn-outline-secondary"
              onClick={close}
              disabled={saving}
            >
              Cancel
            </button>
            <SubmitButton
              className="btn btn-primary ml-2 position-relative"
              onClick={() => send()}
              loading={saving}
            >
              Send
            </SubmitButton>
          </div>
        )}
      </DrawerBase>
    );
  });
