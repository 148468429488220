import { useState } from "react";
import { useApiMutation, usePageData } from "../../utils/api";
import { lorem, useDevTool } from "../../utils/dev_tools";
import { DateField, SelectField, TextField } from "../campaigns/fields";
import { useFormik } from "formik";
import { countries, ethnicities, genders } from "../campaigns/lists";
import * as Yup from "yup";
import { capitalize, pickBy } from "lodash";
import { useParams } from "react-router-dom";
import { SubmitButton } from "../../utils/submit_button";
import { genericError } from "../../utils/errors";

const mockValues = {
  first_name: "James",
  last_name: capitalize(lorem(1)),
  birthdate: "2000-01-01",
  phone: "555-555-5555",
  gender: "Male",
  ethnicity: "Mixed Race",
  country: "United States",
};

const Registration = () => {
  const { data, unavailable } = usePageData();
  const [step, setStep] = useState(1);
  const { uuid: id } = useParams();
  const { post, saving } = useApiMutation();
  const formikProfile = useFormik({
    initialValues: data?.creator || {},
    enableReinitialize: true,
    onSubmit: (values) => {
      setStep(3);
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      birthdate: Yup.string()
        .required("Required")
        .test("age", "Creator must be 18 or older", function (birthdate) {
          const cutoff = new Date();
          cutoff.setFullYear(cutoff.getFullYear() - 18);
          return new Date(birthdate) <= cutoff;
        }),
      phone: Yup.string().required("Required"),
      gender: Yup.string().required("Required"),
      ethnicity: Yup.string().required("Required"),
      country: Yup.string().required("Required"),
    }),
  });

  const formikFinal = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      post("/creators/registrations", {
        ...formikProfile.values,
        ...values,
        id,
      }).then(({ data }) => {
        if (data.success) {
          document.location = "/creators/nda";
        } else {
          genericError();
        }
      });
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Required"),
    }),
  });

  useDevTool(() => {
    formikProfile.setValues({ ...mockValues, ...pickBy(data?.creator || {}) });
    formikFinal.setValues({ password: "asdfasdf" });
  });

  if (unavailable) {
    return unavailable;
  }

  if (!data.creator) {
    document.location = "/sign_in";
  }

  return (
    <div>
      {step === 1 && (
        <div className="text-center">
          <h1>Welcome to Xrossworld</h1>
          <p>Easier brand collaborations start here.</p>
          <table className="my-5 w-400px margin-auto">
            <tbody>
              <tr>
                <td className="p-2">
                  <div className="border round p-2 fs-24">1</div>
                </td>
                <td className="text-left pl-4">Create your account</td>
              </tr>
              <tr>
                <td className="p-2">
                  <div className="border round p-2 fs-24">2</div>
                </td>
                <td className="text-left pl-4">Connect to your platforms</td>
              </tr>
              <tr>
                <td className="p-2">
                  <div className="border round p-2 fs-24">3</div>
                </td>
                <td className="text-left pl-4">View campaign offers</td>
              </tr>
            </tbody>
          </table>

          <button className="btn btn-primary w-100" onClick={() => setStep(2)}>
            Get started
          </button>
        </div>
      )}

      {step === 2 && (
        <div className="mxw-350px margin-auto">
          <h1>Personal Info</h1>

          <form className="my-4" onSubmit={formikProfile.handleSubmit}>
            <div className="mb-3">
              <label>Email</label>
              <div>{data.creator.email}</div>
            </div>

            <TextField
              label="First Name"
              name="first_name"
              className="my-2"
              formik={formikProfile}
            />
            <TextField
              label="Last Name"
              name="last_name"
              className="my-2"
              formik={formikProfile}
            />
            <DateField
              label="Date of Birth"
              name="birthdate"
              className="my-2"
              formik={formikProfile}
            />
            <TextField
              label="Phone"
              name="phone"
              className="my-2"
              formik={formikProfile}
            />
            <SelectField
              label="Gender"
              name="gender"
              options={genders}
              className="my-2"
              formik={formikProfile}
            />
            <SelectField
              label="Ethnicity"
              name="ethnicity"
              options={ethnicities}
              className="my-2"
              formik={formikProfile}
            />
            <SelectField
              label="Country"
              name="country"
              options={countries}
              className="my-2"
              formik={formikProfile}
            />

            <div className="text-center mt-5">
              <button className="btn btn-primary w-100" type="submit">
                Continue
              </button>
              <button className="btn-text link-like mt-3" onClick={() => setStep(1)}>
                Back
              </button>
            </div>
          </form>
        </div>
      )}

      {step === 3 && (
        <div className="mw-350px margin-auto">
          <h1>Create Account</h1>
          <form className="my-4" onSubmit={formikFinal.handleSubmit}>
            <TextField
              label="Set Password"
              name="password"
              type="password"
              formik={formikFinal}
            />

            <div className="text-center mt-4">
              <SubmitButton className="btn btn-primary w-100" loading={saving}>
                Create account
              </SubmitButton>
              <button className="btn-text link-like mt-3" onClick={() => setStep(2)}>
                Back
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Registration;
