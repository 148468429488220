import { Tab, Tabs } from "react-bootstrap";
import { usePageData } from "../../utils/api";
import { Link } from "react-router-dom";
import CampaignCard from "./campaign_card";

const Campaigns = () => {
  const { data, unavailable } = usePageData();

  if (unavailable) {
    return unavailable;
  }

  const { deals } = data;

  return (
    <div>
      <Tabs className="mt-4">
        <Tab title="Active" eventKey="active"></Tab>
        <Tab title="Offers" eventKey="offers"></Tab>
        <Tab title="Completed" eventKey="completed"></Tab>
      </Tabs>

      <div className="my-4">
        {deals.map((deal) => (
          <CampaignCard deal={deal} key={deal.uuid} />
        ))}
      </div>

      <Link to="/creators" className="mt-4 d-block text-center">
        Back to Dashboard
      </Link>
    </div>
  );
};

export default Campaigns;
