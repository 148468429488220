import { useApiGet, useApiMutation } from "../../../utils/api";
import { campaignUuid, PlatformIcon } from "../../campaigns/utils";
import { DrawerBase, makeModal } from "../../../utils/make_modal";
import { toast } from "react-toastify";
import { SubmitButton } from "../../../utils/submit_button";
import { genericError } from "../../../utils/errors";

export const useSingleInviteDrawer = () =>
  makeModal(
    ({
      refetch: refetchParent,
      close,
      object,
    }: {
      refetch: () => void;
      close: () => void;
      object: {
        creator_platform_id: string;
        name: string;
        platform: string;
        handle: string;
        uuid: string;
      };
    }) => {
      const { post, saving } = useApiMutation();
      const { data, unavailable, refetch } = useApiGet(
        `/admin/campaigns/${campaignUuid()}/deals/${object.uuid}`
      );

      if (unavailable) {
        return unavailable;
      }

      const invite = () =>
        post(`/admin/campaigns/${campaignUuid()}/deals/${object.uuid}/invite`).then(
          ({ data }) => {
            if (data.success) {
              refetchParent();
              refetch();
              close();
              toast(`Creator invited`);
            } else {
              genericError();
            }
          }
        );

      const copyInviteLink = (link) => {
        navigator.clipboard.writeText(link);
        close();
        toast("Link copied to clipboard");
      };

      return (
        <DrawerBase title="Creator Invitation" close={close}>
          <div className="mb-5">
            {object.name || <span className="text-muted">&lt;No name&gt;</span>} -{" "}
            <PlatformIcon object={object} />
          </div>

          {data.connected && <div className="mv-3">Creator is connected</div>}
          {data.invited && (
            <div className="mv-3">Creator is currently invited to this campaign</div>
          )}
          {data.invitation_link && (
            <div className="mv-3">
              <button
                className="btn btn-primary"
                onClick={() => copyInviteLink(data.invitation_link)}
              >
                Copy invite link
              </button>
            </div>
          )}
          {data.inviteable && (
            <div className="mv-3">
              <SubmitButton loading={saving} className="btn btn-primary" onClick={invite}>
                Invite creator to this campaign
              </SubmitButton>
            </div>
          )}
          {data.uninviteable && <div className="red mv-3">Creator is not inviteable</div>}
        </DrawerBase>
      );
    }
  );
