export const formatOptions = (options) => options.map((o) => ({ value: o, label: o }));

export const platforms = [
  { value: "tiktok", label: "TikTok" },
  { value: "instagram", label: "Instagram" },
  { value: "youtube", label: "YouTube" },
  { value: "x", label: "X" },
  { value: "linkedin", label: "LinkedIn" },
];

export const countries = [
  "United States",
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of The",
  "Cook Islands",
  "Costa Rica",
  "Cote D'ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and The Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and The South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const categories = [
  "Activist",
  "Actor",
  "Adventure Travel",
  "Aesthetics",
  "Affordable Fashion",
  "Alternative Medicine",
  "Artificial Intelligence",
  "Artist",
  "Athletes",
  "Augmented Reality",
  "Automotive",
  "baker",
  "Baking",
  "BBQ",
  "Beach Life",
  "Beach Sports",
  "Beauty",
  "Beer, Wine, Spirits",
  "Blockchain",
  "Blogger",
  "blue-collar",
  "Body Positivity",
  "Bodybuilding",
  "brick&mortar",
  "Budget Travel",
  "Business Owner",
  "Cannabis",
  "catering",
  "CBD",
  "chef",
  "Christian",
  "City Life",
  "Cloud Computing",
  "Coffee",
  "College athletes",
  "College Students",
  "comedy",
  "consultant",
  "Content Marketing",
  "Cooking Hacks",
  "cooking-show",
  "Cookware",
  "Cosmetics",
  "Cosplay",
  "Creator category",
  "Crypto Enthusiasts",
  "Cryptocurrency Investing",
  "culinary&beverge-pros",
  "Cybersecurity",
  "Dad",
  "Dance Fitness",
  "Dancers",
  "Data Science",
  "Digital Creator",
  "Digital Marketing",
  "DIY & Crafts",
  "DIY Home Projects",
  "Drones",
  "eco-conscious",
  "Eco-Friendly",
  "ecommerce",
  "education",
  "Educators",
  "electrician",
  "Electricians",
  "Electronics Repair",
  "Entertainer",
  "entertainment",
  "Entrepreneur",
  "Ethical Fashion",
  "Ethnic Cuisine",
  "event-planner",
  "Exotic Pets",
  "Extreme Fitness",
  "Extreme Sports",
  "Family",
  "Fashion Hacks",
  "Fashion",
  "fast-casual-dining",
  "Finance",
  "Financial Planning",
  "fine-dining",
  "Fishing & Hunting",
  "Fitness Enthusiasts",
  "Fitness Trainer",
  "Fitness",
  "Food & Beverage",
  "Food Plating & Presentation",
  "Food",
  "food&beverage",
  "Foster Parenting",
  "Furniture & Design",
  "Gadgets",
  "Gaming",
  "gourmet",
  "Gymnast",
  "Hair Care",
  "Hair",
  "head-chef",
  "Health & Wellness",
  "Health",
  "Healthcare",
  "High Fashion",
  "high-intensity",
  "Hiking & Camping",
  "Home & Garden",
  "Home Automation",
  "Home Brewing",
  "Home entertainment",
  "Home Renovation",
  "home-aesthetic",
  "home-cook",
  "home",
  "Homeschooling",
  "Hospitality",
  "Interior Decorating",
  "Interior Designer",
  "interviews",
  "IT Consultants",
  "IT Infrastructure",
  "IT Installers",
  "IT Leaders",
  "IT-installer",
  "kids",
  "LGBTQ",
  "LGBTQ+",
  "Life Coach",
  "Lifestyle",
  "Luxury Goods",
  "Luxury Travel",
  "Man-on-the-street",
  "Marketing",
  "Martial Arts",
  "Mindfulness",
  "Minimalist Living",
  "minimalistic",
  "mixologist",
  "Mixology",
  "Mobile App Developers",
  "Model",
  "Mom",
  "Motherhood",
  "Motorcycle Enthusiasts",
  "Musician",
  "Network Engineers",
  "network-operations",
  "Nursery Design",
  "Nutrition",
  "Outdoor Adventure",
  "Outdoor Furniture & Decor",
  "Outdoors",
  "Parents",
  "Parties",
  "pastry-chef",
  "PC-Builder",
  "Personal Finance",
  "Pet Nutrition",
  "Pet Training",
  "Pets",
  "Photography",
  "Pilates",
  "Plus Size Fashion",
  "Private Schooling",
  "private-chef",
  "Professional Athlete",
  "professional",
  "Public Schooling",
  "Real Estate Investing",
  "Real Estate",
  "Recipes",
  "Religious",
  "restaurant-owner",
  "Robotics",
  "RV Life",
  "Self-care",
  "Selfies",
  "Shoewear",
  "Shows",
  "Single Parenting",
  "Skin Care",
  "Slow Fashion",
  "Smart Home Enthusiasts",
  "Smart Home Gadgets",
  "smarthomes",
  "SMB Business Account",
  "Social Media Marketing",
  "Software Development",
  "Solo Travel",
  "sommelier",
  "Special Needs Parenting",
  "Spooky",
  "Sporting",
  "sports",
  "STEM",
  "Stock Market & Investing",
  "Streetwear",
  "Style",
  "Sustainability",
  "Sustainable Fashion",
  "Sustainable Home Design",
  "Sustainable Living",
  "sustainable",
  "Teacher",
  "Teachers",
  "Tech Entrepreneurs",
  "Tech Events & Conferences",
  "Tech Reviewer",
  "Tech Support Specialists",
  "Tech Thought Leader",
  "Tech",
  "Technology",
  "Teen Parenting",
  "Tiny Homes",
  "Travel & Leisure",
  "Travel Hacks",
  "TV-personality",
  "Urban Gardening",
  "Van Life",
  "Vegan Cooking",
  "Vegan",
  "Vintage Fashion",
  "Virtual Reality",
  "VR",
  "Web Developers",
  "Wedding & Bridal",
  "Wellness",
  "wifi-expert",
  "WiFi",
  "Wine Tasting",
  "Winter Sports",
  "Women's Health",
  "working-professional",
  "Workouts",
  "Yoga",
  "Zero Waste",
];

export const ethnicities = [
  "African American",
  "African",
  "Arab",
  "Asian",
  "Black",
  "Caribbean",
  "East Asian",
  "European",
  "Hispanic/Latino",
  "Indigenous",
  "Latino",
  "Middle Eastern",
  "Mixed Race",
  "Native American",
  "Pacific Islander",
  "South Asian",
  "Southeast Asian",
  "White/Caucasian",
];

export const genders = ["Male", "Female", "Other"];

export const ageRanges = ["1-17", "18-24", "25-34", "35-44", "45-54", "55+"];

export const campaignStatuses = [
  { value: "onboarding_in_progress", label: "Onboarding in progress" },
  { value: "onboarding_complete", label: "Onboarding complete" },
  { value: "packages_submitted", label: "Packages submitted" },
  { value: "package_selected", label: "Package selected" },
  { value: "campaign_in_progress", label: "Campaign in progress" },
  { value: "campaign_complete", label: "Campaign complete" },
];
