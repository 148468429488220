import { random, shuffle } from "lodash";

const sampleCreators = [1, 7, 8, 9, 10, 13, 14, 15, 16, 17, 18, 20, 21, 22, 23];

export const fillOutPackageForm = (formik, setNewlyAddedCreators) => {
  const creatorIds = shuffle(sampleCreators).slice(0, random(1, 4));

  setNewlyAddedCreators(
    creatorIds.map((id) => ({ id, platform: "instagram", handle: `Creator ${id}` }))
  );

  formik.setValues({
    cost: `$${random(16, 200) * 500}`,
  });

  setTimeout(() => {
    (
      document.querySelector(
        "form[name=package-form] button[type=submit]"
      ) as HTMLButtonElement
    ).click();
  }, 500);
};
