import { Link } from "react-router-dom";
import { usePageData } from "../../utils/api";
import { formatDate, PlatformIcon } from "../campaigns/utils";
import { dealStatusBadge } from "../../utils/deal_status";
import { Alert } from "react-bootstrap";
import { formatCurrency } from "../../utils/helpers";
import { QuestionTooltip } from "../campaigns/tooltip";
import { CALL_TO_ACTIONS, CREATOR_OBJECTIVES } from "../campaigns/creator_brief";

const formatList = (items) => (
  <ul>
    {items?.map((i) => (
      <li key={i}>{i}</li>
    ))}
  </ul>
);
const Campaign = () => {
  const { data, unavailable } = usePageData();

  if (unavailable) {
    return unavailable;
  }

  const { deal } = data;
  const { campaign } = deal;

  return (
    <>
      {deal.status === "accepted_offer" && (
        <Alert variant="secondary">
          You have accepted the campaign offer. This does not guarantee the campaign. The
          client has until Nov 12, 2024 to make a final decision. We’ll notify you either
          way.
        </Alert>
      )}

      <h2>{campaign.name}</h2>
      <div>{campaign?.agency?.name}</div>

      <h3 className="mt-8 mb-3">Key Info</h3>

      <div className="fw-600">Campaign term</div>
      <div>
        {formatDate(campaign.posting_start_date)} -{" "}
        {formatDate(campaign.posting_end_date)}
      </div>
      <hr />
      <div className="fw-600">Platform</div>
      <div>
        <PlatformIcon platform={campaign.platform} self />
      </div>
      <hr />
      <div className="fw-600">About the campaign</div>
      <div>{campaign.about}</div>
      <hr />

      <h3 className="mt-4 mb-4">Details</h3>

      <div className="fw-600">Content style</div>
      <div>
        {formatList(
          campaign.campaign_purposes.map((item) => (
            <>
              {item}{" "}
              <QuestionTooltip
                tip={
                  CREATOR_OBJECTIVES.find((obj) => obj.value === item)?.description || ""
                }
              />
            </>
          ))
        )}
      </div>
      <hr />
      <div className="fw-600">Content types</div>
      <div>{formatList(campaign.content_types)}</div>
      <hr />
      <div className="fw-600">Objective</div>
      <div>{formatList(campaign.objectives)}</div>
      <hr />
      <div className="fw-600">Call to action</div>
      <div>
        {formatList(
          campaign.add_ons.map((item) => (
            <>
              {item}{" "}
              <QuestionTooltip
                tip={CALL_TO_ACTIONS.find((obj) => obj.value === item)?.description || ""}
              />
            </>
          ))
        )}
      </div>
      <hr />

      {deal.counter_offer && (
        <>
          <h3 className="mt-4 mb-4">Campaign cost</h3>
          <div className="fw-600">Price</div>
          <div>{formatCurrency(deal.counter_offer)}</div>
        </>
      )}

      {!deal.counter_offer && (
        <Link to={`${deal._link}/offer`} className="mt-6 btn btn-primary w-100">
          Continue
        </Link>
      )}

      <Link to="/creators" className="mt-2 d-block text-center">
        Back to Dashboard
      </Link>
    </>
  );
};

export default Campaign;
