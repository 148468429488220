import { useNavigate } from "react-router-dom";
import { useTemplate2 } from "./utils";
import { clickPrimaryButton, useDevTool } from "../../utils/dev_tools";
import { useApiMutation } from "../../utils/api";
import Faqs from "./faq";
import { SubmitButton } from "../../utils/submit_button";

const steps = [
  {
    title: "Discover and Match",
    body: "Our AI discovers and automatically matches the most relevant creators for your campaign, ensuring they align with your goals, audience, and budget.",
    img: "icon-discover.png",
  },
  {
    title: "Negotiate",
    body: "Our AI handles all negotiations, securing the best rates and agreements on your behalf to ensure transparency and the fairest market value.",
    img: "icon-handshake.png",
  },
  {
    title: "Choose",
    body: "Select from AI-curated campaign packages that have been tailored to meet your campaign objectives, audience, and budget.",
    img: "icon-briefcase.png",
  },
];

const faqs = [
  {
    title: "How does Xrossworld match creators to my campaign?",
    body: "Our AI-powered platform analyzes your campaign goals, target audience, and budget to automatically match you with the most relevant creators. The selection process is data-driven, ensuring the creators align with your specific needs for maximum impact.",
  },
  {
    title: "Can I manually select creators for my campaign?",
    body: "No manual selection is required. Our AI selects the most suitable creators for your campaign based on your brief. You’ll receive a curated package of creators that best match your campaign objectives and audience.",
  },
  {
    title: "How are creator rates and agreements handled?",
    body: "Our AI negotiates the best rates with creators on your behalf, ensuring fair market value and transparency in all agreements. You don’t need to worry about negotiating terms—we handle everything for you.",
  },
];

const CampaignsIntro = () => {
  useTemplate2();
  useDevTool(clickPrimaryButton);
  const navigate = useNavigate();
  const { post, saving } = useApiMutation();

  const initiate = () => {
    post("/campaigns").then(({ data }) => {
      if (data.uuid) {
        navigate(`/campaigns/${data.uuid}/platform`);
      }
    });
  };

  return (
    <div id="main" className="container container-narrow">
      <div className="row">
        <div className="col">
          <div className="text-center mt-5">
            <h1 className="fs-46">Simplifying Your Campaign Journey</h1>
            <br />
            <p className="light-gray fs-20">
              From discovering the best creators to negotiating fair rates and choosing
              tailored packages, our platform handles every step.
            </p>
          </div>

          <div className="wrapper py-3">
            <h4 className="mt-5 mb-4 text-center">How it Works</h4>

            <div className="row row-cols-1 row-cols-md-3 g-4">
              {steps.map((step) => (
                <div className="col" key={step.title}>
                  <div className="card h-100">
                    <div className="card-body">
                      <img
                        src={`/images/${step.img}`}
                        className="img-fluid"
                        width={283}
                      />
                      <h4 className="card-title text-center mt-4">{step.title}</h4>
                      <p>{step.body}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="page-actions mt-5">
            <SubmitButton
              className="btn btn-xw-primary btn-lg"
              onClick={initiate}
              loading={saving}
            >
              Discover your perfect creators
            </SubmitButton>
          </div>

          <Faqs faqs={faqs} />
        </div>
      </div>
    </div>
  );
};

export default CampaignsIntro;
