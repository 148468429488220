import { Modal } from "react-bootstrap";
import { makeModal, ModalBase } from "../../utils/make_modal";

export const useTermsModal = () =>
  makeModal(({ close }: { close: () => void }) => {
    return (
      <ModalBase title="Terms & Conditions" close={close} className="modal-xl">
        <Modal.Body>
          <p>
            Welcome to XrossWorld! These terms and conditions (“Terms”) govern your access
            to and use of XrossWorld’s platform and services as an agency user. By
            creating an account and using our platform, you agree to comply with these
            Terms. Please read them carefully.
          </p>

          <ol>
            <li className="mt-1r">
              <h6 className="mb-_5r">Acceptance of Terms</h6>
              <p>
                By using the XrossWorld platform, you agree to be bound by these Terms,
                along with our Privacy Policy and any additional guidelines or policies
                provided by XrossWorld. If you do not agree to these Terms, do not use the
                platform.
              </p>
            </li>
            <li className="mt-1r">
              <h6 className="mb-_5r">Eligibility</h6>
              <p>
                To register as an agency user, you must be legally capable of entering
                into a binding agreement and agree to provide accurate information during
                registration. You represent and warrant that you are authorized to act on
                behalf of the agency.
              </p>
            </li>
            <li className="mt-1r">
              <h6 className="mb-_5r">Account Registration and Security</h6>
              <ul>
                <li>
                  You are responsible for maintaining the confidentiality of your account
                  credentials.
                </li>
                <li>
                  You agree to notify XrossWorld immediately if you suspect unauthorized
                  use of your account.
                </li>
                <li>
                  XrossWorld reserves the right to terminate or suspend accounts at its
                  discretion.
                </li>
              </ul>
            </li>
            <li className="mt-1r">
              <h6 className="mb-_5r">Platform Use and License</h6>
              <ul>
                <li>
                  XrossWorld grants you a limited, non-exclusive, non-transferable license
                  to use the platform solely for managing influencer campaigns.
                </li>
                <li>
                  You agree not to misuse the platform, interfere with its operation, or
                  use it for any illegal activities.
                </li>
              </ul>
            </li>
            <li className="mt-1r">
              <h6 className="mb-_5r">Campaign Management and Payments</h6>
              <ul>
                <li>
                  XrossWorld facilitates campaign management and payments to influencers
                  on your behalf.
                </li>
                <li>
                  You agree that XrossWorld is not liable for any third-party obligations
                  arising from your campaigns, including undisclosed paid partnerships.
                </li>
              </ul>
            </li>
            <li className="mt-1r">
              <h6 className="mb-_5r">Confidentiality</h6>
              <p>
                You agree not to disclose any information related to your campaigns or
                influencers without XrossWorld’s consent. Any confidentiality breaches are
                subject to investigation, and XrossWorld reserves the right to take
                corrective actions.
              </p>
            </li>
            <li className="mt-1r">
              <h6 className="mb-_5r">Intellectual Property</h6>
              <ul>
                <li>
                  XrossWorld retains all rights to its intellectual property, including
                  trademarks, logos, and proprietary information.
                </li>
                <li>
                  You may not reproduce, distribute, or display XrossWorld’s IP without
                  prior written consent.
                </li>
              </ul>
            </li>
            <li className="mt-1r">
              <h6 className="mb-_5r">Fees and Payment</h6>
              <ul>
                <li>
                  Subscription fees, transaction fees, and other applicable charges will
                  be clearly outlined before any transactions.
                </li>
                <li>
                  You are responsible for timely payment of all fees. Late or missed
                  payments may result in account suspension or termination.
                </li>
              </ul>
            </li>
            <li className="mt-1r">
              <h6 className="mb-_5r">Campaign Content and Creator Agreement</h6>
              <ul>
                <li>
                  You agree that XrossWorld manages communications and agreements with
                  creators on your behalf.
                </li>
                <li>
                  XrossWorld reserves the right to approve content before posting. You
                  understand that content that does not align with XrossWorld’s guidelines
                  or is delayed in approval will not hold XrossWorld accountable.
                </li>
              </ul>
            </li>
            <li className="mt-1r">
              <h6 className="mb-_5r">Prohibited Activities</h6>
              <p>You agree not to:</p>
              <ul>
                <li>Use the platform for unlawful purposes.</li>
                <li>
                  Attempt to reverse-engineer, disassemble, or otherwise tamper with any
                  aspect of XrossWorld.
                </li>
                <li>
                  Directly contact creators or other platform users in a manner that
                  breaches XrossWorld’s communication guidelines.
                </li>
              </ul>
            </li>
            <li className="mt-1r">
              <h6 className="mb-_5r">Liability and Disclaimers</h6>
              <ul>
                <li>
                  XrossWorld is not responsible for any indirect, incidental, or
                  consequential damages arising from your use of the platform.
                </li>
                <li>
                  XrossWorld does not guarantee specific outcomes for campaigns; all
                  projected results are estimates based on negotiated values and creator
                  history.
                </li>
              </ul>
            </li>
            <li className="mt-1r">
              <h6 className="mb-_5r">Termination</h6>
              <p>
                XrossWorld reserves the right to terminate or suspend your account at its
                discretion, with or without notice, if these Terms are violated or for any
                other reason deemed appropriate by XrossWorld.
              </p>
            </li>
            <li className="mt-1r">
              <h6 className="mb-_5r">Modifications to Terms</h6>
              <p>
                XrossWorld may update these Terms from time to time. Continued use of the
                platform constitutes your acceptance of the revised Terms.
              </p>
            </li>
            <li className="mt-1r">
              <h6 className="mb-_5r">Governing Law and Jurisdiction</h6>
              <p>
                These Terms are governed by and construed in accordance with the laws of
                [Country/State]. Any disputes shall be resolved in the courts of
                [Jurisdiction].
              </p>
            </li>
          </ol>

          <h6 className="mb-_5r">Acceptance</h6>
          <p>
            By using XrossWorld, you acknowledge that you have read, understood, and agree
            to these Terms.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={close}>
            Close
          </button>
        </Modal.Footer>
      </ModalBase>
    );
  });
