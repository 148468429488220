import { Link, useSearchParams } from "react-router-dom";
import { getCampaignSteps } from "./campaign_steps";
import { SubmitButton } from "../../utils/submit_button";

const ActionButtons = ({ label, saving }: { label?: string; saving?: boolean }) => {
  const uuid = location.pathname.split("/")[2];
  const [searchParams] = useSearchParams();

  const prevStep = getCampaignSteps().prev;

  let backLink;
  let backLabel;
  let nextLabel;

  if (searchParams.get("mode") === "edit") {
    backLink = `/campaigns/${uuid}/review`;
    backLabel = "Back to Review";
    nextLabel = "Save";
  } else {
    backLink = `/campaigns/${uuid}/${prevStep || ""}`;
    backLabel = "Back";
    nextLabel = label || "Continue";
  }

  return (
    <div className="page-actions">
      <div>
        <Link className="btn btn-xw-secondary btn-lg" to={backLink}>
          {backLabel}
        </Link>

        <SubmitButton className="btn btn-xw-primary btn-lg" loading={saving}>
          {nextLabel}
        </SubmitButton>
      </div>
      <div className="mt-4">
        <Link to="/campaigns">Save and Exit</Link>
      </div>
    </div>
  );
};

export default ActionButtons;
