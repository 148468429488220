import { useQuery } from "react-query";
import axios from "axios";
import { genericError } from "./errors";
import { useState } from "react";

export default function useLazyQuery(key: string, fn: () => any, options = {}) {
  const query = useQuery(key, fn, {
    ...options,
    enabled: false,
  });

  return [query.refetch, query];
}

export const useApiGet = (path: string, sub?: string) => {
  let unavailable;
  let data;

  const q = useQuery(path, () => axios.get(path).then((res) => res.data), {
    enabled: true,
  });

  if (q.isFetching && !q.isFetched) {
    unavailable = <div></div>;
  } else if (q.error) {
    unavailable = <div>There was an error</div>;
    genericError();
  } else if (q.isSuccess) {
    data = q.data;
  }

  return {
    [sub ? sub : "data"]: sub ? data?.[sub] : data,
    unavailable,
    refetch: q.refetch,
  };
};

export const usePageData = (root?: string) => {
  return useApiGet(location.pathname + location.search, root);
};

export const apiGet = (path, params = {}) => {
  const promise = axios.get(path, params);
  promise.catch(genericError);
  return promise;
};

export const apiPut = (
  path,
  data = {},
  config = {},
  setSaving: (value: boolean) => void
) => {
  setSaving?.(true);
  const promise = axios.put(path, data, config);
  promise.catch(genericError);
  promise.finally(() => setSaving?.(false));
  return promise;
};

export const apiPost = (
  path,
  data = {},
  config = {},
  setSaving: (value: boolean) => void
) => {
  setSaving?.(true);
  const promise = axios.post(path, data, config);
  promise.catch(genericError);
  promise.finally(() => setSaving?.(false));
  return promise;
};

export const apiDelete = (path) => {
  const promise = axios.delete(path);
  promise.catch(genericError);
  return promise;
};

export const useApiPut = () => {
  let callback;
  const [saving, setSaving] = useState(false);
  const put = (path: string, data = {}, config = {}) => {
    setSaving(true);
    const promise = axios.put(path, data, config);
    promise.catch(genericError);
    promise.finally(() => setSaving(false));
    promise.then(({ data }) => callback?.(data));
    return promise;
  };
  return { put, saving };
};

export const useApiPost = () => {
  let callback;
  const [saving, setSaving] = useState(false);
  const post = (path: string, data = {}, config = {}) => {
    setSaving(true);
    const promise = axios.post(path, data, config);
    promise.catch(genericError);
    promise.finally(() => setSaving(false));
    promise.then(({ data }) => callback?.(data));
    return promise;
  };
  return { post, saving };
};

export const useApiMutation = () => {
  let callback;

  const [saving, setSaving] = useState(false);

  const makeRequest =
    (method: "put" | "post" | "delete") =>
    (path: string, data = {}, config = {}) => {
      setSaving(true);
      const promise = axios[method](path, data, config);
      promise.catch(genericError);
      promise.finally(() => setSaving(false));
      promise.then(({ data }) => callback?.(data));
      return promise;
    };

  const put = makeRequest("put");
  const post = makeRequest("post");
  const apiDelete = makeRequest("delete");
  const destroy = makeRequest("delete");

  return { post, put, apiDelete, destroy, saving };
};
